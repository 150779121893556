:root {
  // new vars
  --brand-orange-50: #fef6f0;
  --Brand-Orange-500: #ea6b3d;
  --brand-orange-600: #e24e29;

  --Brand-Cyan-300: #7ad2e7;

  --Neutral-0: #ffffff;
  --Neutral-25: #f7f8fa;
  --Neutral-50: #f1f1f4;
  --Neutral-100: #eaeaf0;
  --Neutral-200: #dadbe2;
  --Neutral-300: #c1c2cd;
  --Neutral-400: #8f90a3;
  --Neutral-500: #5e617b;
  --Neutral-600: #414362;
  --Neutral-700: #323458;
  --Neutral-800: #282a4f;
  --Neutral-Primary: #202145;
  --Neutral-Secondary: #5e617b;

  --Neutral-0-rgb: 255, 255, 255;
  --Neutral-Primary-rba: 32, 33, 69;

  --Background-Neutral-Container: var(--Neutral-0);
  --Background-Neutral-Primary: var(--Neutral-50);
  --Background-Neutral-Secondary: var(--Neutral-100);

  --Border-Neutral-Default: var(--Neutral-200);

  --Fill-Brand-Orange-Default: var(--brand-orange-50);
  --Fill-Brand-Orange-Hover: var(--Brand-Orange-500);

  --Text-onNeutral-Brand-Default: var(--brand-orange-600);
  --Text-onLight-Neutral-Secondary: var(--Neutral-500);
  --Text-onLight-Neutral-Primary: var(--Neutral-Primary);
  --Text-onLight-Neutral-Tertiary: var(--Neutral-400);
  --Text-onDark-Cyan: var(--Brand-Cyan-300);
  --Text-onDark-Neutral-Secondary: var(--Neutral-300);
  --Text-Neutral-Secondary: var(--Neutral-300);
  --Text-Brand-Cyan-Default: var(--color-cyan);

  --shadow-xs: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
  --shadow-sm: 10px 10px 16px 0px rgba(48, 48, 48, 0.04), 1px 1px 8px 0px rgba(48, 48, 48, 0.04);
  --shadow-md: 0px 121px 80px 0px rgba(175, 182, 202, 0.07), 0px 50.551px 33.422px 0px rgba(175, 182, 202, 0.05),
    0px 27.027px 17.869px 0px rgba(175, 182, 202, 0.04), 0px 15.151px 10.017px 0px rgba(175, 182, 202, 0.04),
    0px 8.047px 5.32px 0px rgba(175, 182, 202, 0.03), 0px 3.348px 2.214px 0px rgba(175, 182, 202, 0.02);
  --shadow-lg: 0px 120px 80px 0px rgba(175, 182, 202, 0.14), 0px 56px 33.422px 0px rgba(175, 182, 202, 0.1),
    0px 24px 17.869px 0px rgba(175, 182, 202, 0.08), 0px 8px 5.32px 0px rgba(175, 182, 202, 0.04),
    0px 16px 10.017px 0px rgba(175, 182, 202, 0.07), 0px 4px 2.214px 0px rgba(175, 182, 202, 0.02);
  // new vars end

  // --color-main: #ff6f37;
  --color-main: var(--brand-orange-600);
  --color-black: var(--Neutral-Primary);
  --color-white: var(--Neutral-0);
  --color-white-75: rgba(255, 255, 255, 0.75);
  --color-white-50: rgba(255, 255, 255, 0.5);
  --color-white-25: rgba(255, 255, 255, 0.25);
  --color-white-15: rgba(255, 255, 255, 0.15);

  --color-gray-50: #f9f9fa;
  --color-gray-100: #f6f8fb;
  --color-gray-200: #f1f3f8;
  --color-gray-300: #e3e9ef;
  --color-gray-400: #dbe0e7;
  --color-gray-500: #bec8d5;
  --color-gray-600: #afb9c6;
  --color-gray-700: #909dad;
  --color-gray-800: #4e5463;
  --color-gray-900: #233142;

  --color-red-100: #fdecec;
  --color-red-200: #fde0e0;
  --color-red-400: #f56565;

  --color-green-200: #d4eedd;
  --color-green-400: #48bb78;
  --color-green-600: #008839;

  --color-caution-200: #facc96;
  --color-caution-400: #faaa4b;
  --color-caution-600: #b36100;

  --color-info-200: #c2d2f2;
  --color-info-400: #4774cc;
  --color-info-600: #214ea6;

  --color-space-dark: #0c0f3c;
  --color-space-blue-1: #1d2264;
  --color-space-blue-2: #23286f;
  --color-space-blue-3: #292e6d;
  --color-orange: #ea6b3d;
  // old
  // --color-light-orange: #fff6f3;
  // new
  --color-light-orange: var(--brand-orange-50);
  // old
  // --color-dark-orange: #d85a2c;
  // new
  --color-dark-orange: var(--brand-orange-600);
  --color-purple: #7c4a66;
  --color-green: #80d0b8;
  --color-dark-green: #176659;
  --color-yellow: #f4b840;
  --color-cyan: #7ad2e7;
  --color-light-blue: #baecf7;
  --color-grey: #a5abb2;
  --color-light-gray-3: #e6e6e6;

  --shadow-card-hover: 0px 121px 80px rgba(175, 182, 202, 0.07), 0px 50.5509px 33.4221px rgba(175, 182, 202, 0.0503198),
    0px 27.0269px 17.869px rgba(175, 182, 202, 0.0417275), 0px 15.1511px 10.0172px rgba(175, 182, 202, 0.035),
    0px 8.04662px 5.32008px rgba(175, 182, 202, 0.0282725), 0px 3.34838px 2.21381px rgba(175, 182, 202, 0.0196802);

  --border-radius-1: 4px;
  --border-radius-2: 8px;
  --border-radius-3: 12px;

  --wrap-max-width-1: 1088px;
  --wrap-max-width-2: 1312px;
  --wrap-pad-1: 40px;
  --wrap-pad-2: 64px;

  --header-height: 80px;
  --header-bar-height: 48px;

  --transition-the-fastest: 0.15s;
  --transition-fast: 0.3s;
  --transition-average: 0.6s;
  --transition-slow: 0.9s;
}

::-moz-selection {
  color: var(--color-white);
  background: var(--color-space-blue-3);
}

::selection {
  color: var(--color-white);
  background: var(--color-space-blue-3);
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: var(--color-black);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-main);
}

body {
  font-family: 'Axiforma', sans-serif;
  font-weight: 400;
  color: var(--color-black);
  min-height: 100vh;
}

svg {
  display: block;
  max-width: 100%;
  height: auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

mark {
  color: var(--color-main);
  background: initial;
}

b,
strong {
  font-weight: 800;
  color: var(--color-main);
}
