.container {
  opacity: 0;
  transition: var(--transition-fast);

  &--style-onForeground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 88;
    overflow: hidden;
  }

  &--anim-on-enter,
  &--anim-on-enter-done {
    opacity: 1;
  }

  &--anim-on-exit {
    opacity: 0;
    transition: var(--transition-the-fastest);
  }
}
