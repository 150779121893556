.container {
  .item {
    text-align: center;

    &__ill {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 192px;
      margin-bottom: 32px;
      position: relative;
    }

    &__text {
      margin-top: 16px;
    }
  }
}
