.step {
  display: flex;
  align-items: center;

  &__circle {
    background-color: var(--color-gray-200);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 11px;
    transition: var(--transition-fast);

    &__number {
      transition: var(--transition-fast);
    }
  }

  &__label {
    &__text {
      &--original {
        opacity: 1;
        transition: var(--transition-fast);
      }

      &--current {
        font-weight: 700 !important;
        opacity: 0;
        width: 105%;
        position: absolute !important;
        top: 0px;
        transition: var(--transition-fast);
      }
    }
  }

  &--current {
    .step__circle {
      background-color: var(--color-main);

      &__number {
        color: #ffffff;
      }
    }

    .step__label {
      position: relative;

      &__text {
        &--original {
          opacity: 0;
        }

        &--current {
          opacity: 1;
        }
      }
    }
  }

  &--past {
    .step__circle {
      background-color: var(--color-gray-800);
    }
  }
}

@media screen and (max-width: 1279px) {
  .step {
    display: none;
  }
}
