.rocket-fuel {
  .account-boost {
    &__title-outer {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    &__title {
      &__label {
        margin-left: 16px;
      }
    }

    &__text {
      margin-top: 16px;
    }

    &__button {
      margin-top: 32px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
    }
  }

  .credits {
    // margin-top: 104px;

    &__text {
      margin-top: 16px;
    }

    &__grid {
      margin-top: 48px;
    }
  }
}

.buy-credits-pop-up {
  &__price {
    color: var(--color-gray-800);
    display: flex;
    align-items: center;
    margin-top: 8px;

    &__dot {
      background-color: var(--color-gray-800);
      border-radius: 50%;
      width: 4px;
      height: 4px;
      margin-right: 12px;
      margin-left: 12px;
      position: relative;
      top: -2px;
    }
  }

  &__text-2 {
    margin-top: 24px;
  }

  &__text-3 {
    margin-top: 24px;
  }

  &__alert {
    margin-top: 24px;
  }

  &__footer-button-list {
    display: flex;
    justify-content: flex-end;

    &__item:not(:nth-last-child(1)) {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .rocket-fuel {
    .account-boost {
      &__footer {
        flex-wrap: wrap;
        align-items: initial;
        justify-content: initial;

        &__countdown {
          order: 1;
        }

        &__button-2 {
          order: 2;
          margin-top: 16px;
        }
      }
    }
  }

  .buy-credits-pop-up {
    &__footer-button-list {
      flex-wrap: wrap;
      justify-content: initial;

      &__item:not(:nth-last-child(1)) {
        margin-right: initial;
        margin-bottom: 8px;
      }

      &__item {
        width: 100%;

        & > * {
          width: 100%;
        }
      }
    }
  }
}
