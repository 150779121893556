.container {
  display: flex;
  // overflow: hidden;

  &__loader {
    max-width: 140px;
    height: auto;
  }

  &--var-default {
    align-items: center;
    justify-content: center;
  }

  &--var-blurredBackground {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    &:before {
      content: '';
      backdrop-filter: blur(4px);
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      display: block;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      position: absolute;
      top: -20px;
      left: -20px;
      pointer-events: none;
    }
  }

  &--color-onBlue {
    &:before {
      content: '';
      backdrop-filter: blur(4px);
      background-color: rgba(28, 33, 99, 0.8);
      display: block;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      position: absolute;
      top: -20px;
      left: -20px;
      pointer-events: none;
      filter: blur(4px);
    }

    .container__loader {
      svg {
        g g g:nth-child(2) path {
          fill: var(--color-white);
        }
      }
    }
  }

  &--logo-position-top {
    align-items: flex-start;
    padding-top: var(--wrap-pad-2);
  }
}
