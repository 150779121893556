.hero-price-container {
  margin-top: 50px;

  .hero-price__text-1 {
    color: var(--Text-onLight-Neutral-Secondary, #5e617b);
    margin-bottom: 6px;
  }
}

.hero-brand-container {
  text-align: center;
  display: flex;
  justify-content: flex-end;
  width: 128%;
  position: relative;
  margin-top: 40px;

  &__inner {
    min-width: 390px;
  }

  .hero-brand__text-1 {
    color: var(--Text-onLight-Neutral-Primary, #202145);
    margin-bottom: -4px;

    &__part-1 {
      text-transform: capitalize;
    }

    &__part-2 {
      color: var(--Text-onLight-Neutral-Primary, #202145);
    }
  }
}

.pre-footer-button {
  text-align: center;
  margin-top: 64px;
}

@media screen and (max-width: 1279px) {
  .hero-brand-container {
    justify-content: center;
    width: 100%;
  }

  .hero-brand-container__inner {
    min-width: initial;
  }
}
