.text {
  font-family: 'Axiforma', sans-serif;
  position: relative;

  &--h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 72px;
    letter-spacing: -0.48px;
  }

  &--h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -0.4px;
  }

  &--h3 {
    font-size: 32px;
    font-weight: 800;
    line-height: 125%;
    letter-spacing: 0.02em;

    &--font-weight-400 {
      font-weight: 400;
    }
  }

  &--h4 {
    font-size: 24px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.24px;
  }

  &--h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &--caps {
    font-size: 16px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &--caps-small {
    font-size: 12px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &--body-1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.01em;
  }

  &--body-2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  &--body-2-var-a {
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: -0.18px;
  }

  &--body-3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 142.857%;
  }

  &--caption {
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  }

  &--tiny {
    font-size: 10px;
    font-weight: 500;
    line-height: 150%;
  }

  &--bold,
  .bold {
    font-weight: 800;
  }

  &--bold-2,
  .bold--2 {
    font-weight: 700;
  }

  &:is(a):after {
    content: '';
    background-color: initial;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .text {
    font-family: 'Axiforma', sans-serif;
    position: relative;

    &--h1 {
      font-size: 40px;
      line-height: 60px;
      letter-spacing: -0.36px;
    }

    &--h2 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.32px;
    }

    &--h3 {
      font-size: 32px;
    }

    &--h4 {
      font-size: 24px;
    }

    &--h5 {
      font-size: 20px;
    }

    &--caps {
      font-size: 16px;
    }

    &--caps-small {
      font-size: 12px;
    }

    &--body-1 {
      font-size: 20px;
    }

    &--body-2 {
      font-size: 16px;
    }

    &--caption {
      font-size: 12px;
    }

    &--tiny {
      font-size: 10px;
    }
  }
}
