.hero {
  &__title {
    &__mark {
      color: var(--Text-onDark-Cyan);
    }
  }

  &__text {
    color: var(--Text-Neutral-Secondary);
  }
}

.section-partner {
  &__text {
    margin-top: 20px;
  }

  &__block-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    width: calc(100% + 16px + 16px);
    position: relative;
    left: -16px;
    margin-top: 64px;

    &__item {
      width: calc(50% - 16px - 16px);
      margin-right: 16px;
      margin-left: 16px;
    }

    &__tile {
      height: 100%;
      overflow: hidden;
    }

    &__text-1 {
      color: var(--Text-onNeutral-Brand-Default);
      margin-bottom: 8px;
    }

    &__text-2 {
      color: var(--Neutral-500);
      margin-top: 16px;
    }

    &__ill-container {
      width: 100%;
      height: 260px;
      margin-top: 24px;
      position: relative;
    }

    &__ill-1 {
      max-width: initial;
      position: absolute;
      top: -100px;
      right: -96px;
    }

    &__ill-2 {
      max-width: initial;
      position: absolute;
      top: -5px;
      right: -137px;
    }
  }

  &__button-container {
    text-align: center;
    margin-top: 64px;
  }
}

@media screen and (max-width: 1279px) {
  .section-partner {
    &__text {
    }

    &__block-list {
      width: 100%;
      left: initial;

      &__item {
        width: 100%;
        margin-right: initial;
        margin-left: initial;
      }
    }
  }
}
