.profile {
  .title-2 {
    color: var(--color-main);
  }

  .delete-account {
    margin-top: 32px;
  }

  .roles {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__list {
      margin-top: 32px;

      &__item {
        &__button {
          display: block;
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }

  .account {
    margin-top: 104px;

    &__submit {
      margin-top: 32px;
    }

    &__personal-info {
      margin-top: 32px;

      &__avatar-container {
        display: flex;
        align-items: center;
        margin-top: 32px;

        &__image {
          border-radius: 31px;
          width: 104px;
          height: 104px;
          margin-right: 32px;
          overflow: hidden;
        }

        &__input {
          &__tip {
            margin-top: 16px;
          }
        }
      }

      &__input-group {
        margin-top: 32px;
      }
    }

    &__change-pass {
      margin-top: 32px;

      &__input-group {
        margin-top: 32px;
      }
    }
  }
}

.delete-acc-pop-up {
  &__icon-container {
    background-color: var(--color-red-100);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  &__title {
    margin-top: 64px;
  }

  &__text {
    color: var(--color-gray-900);

    b {
      color: var(--color-gray-900);
    }
  }

  &__text-1 {
    margin-top: 32px;
  }

  &__text-2 {
    margin-top: 32px;
  }

  &__text-3 {
    margin-top: 32px;
  }

  &__footer-button-list {
    display: flex;
    justify-content: flex-end;

    &__item:not(:nth-last-child(1)) {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .profile {
    .roles {
      &__header {
        flex-wrap: wrap;
        justify-content: initial;
        align-items: initial;

        &__add-company-button {
          margin-top: 32px;
        }
      }
    }

    .account {
      &__personal-info {
        &__avatar-container {
          align-items: initial;
          flex-wrap: wrap;

          &__image {
            border-radius: 31px;
            width: 104px;
            height: 104px;
            margin-right: 32px;
            overflow: hidden;
          }

          &__input {
            margin-top: 32px;
            &__tip {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  .delete-acc-pop-up {
    &__footer-button-list {
      justify-content: initial;
      flex-wrap: wrap;

      &__item:not(:nth-last-child(1)) {
        margin-right: initial;
        margin-bottom: 8px;
      }

      &__item {
        width: 100%;

        & > * {
          width: 100%;
        }
      }
    }
  }
}
