.account-template {
  .main {
    position: relative;
    min-height: calc(100vh - 454px);

    :global(.content) {
      position: relative;
    }

    &__part-list {
      display: flex;

      &__item-1 {
        width: 304px;
        margin-right: 32px;
      }

      &__item-2 {
        width: calc(100% - 304px - 32px);
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .account-template {
    .main {
      &__part-list {
        &__item-1 {
          width: 240px;
          margin-right: 24px;
        }

        &__item-2 {
          width: calc(100% - 240px - 24px);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .account-template {
    .main {
      &__part-list {
        flex-wrap: wrap;

        &__item-1 {
          width: 100%;
          margin-right: initial;
        }

        &__item-2 {
          width: 100%;
          margin-top: 40px;
        }
      }
    }
  }
}
