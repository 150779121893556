.card {
  border-radius: 8px;
  border: 1px solid var(--color-gray-200);
  background: var(--color-white);
  padding: 24px;

  .title-container {
    display: flex;
    align-items: center;

    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-left: 16px;

      &--up {
        transform-origin: center;
        transform: rotate(180deg);

        svg {
          fill: var(--color-green-400);
        }
      }

      &--down {
        svg {
          fill: var(--color-red-400);
        }
      }
    }
  }

  .text {
    color: var(--color-gray-800);
    margin-top: 16px;
  }
}