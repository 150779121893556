.radio-list {
  &__item {
    &:not(:nth-child(1)) {
      margin-top: 16px;
    }
  }
}

.radio {
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  &__input-original {
    display: none;
  }

  &__input-custom {
    background: var(--color-white);
    border: 2px solid var(--color-gray-300);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;

    transition: var(--transition-fast);

    &:after {
      content: '';
      background-color: var(--color-main);
      border-radius: 50%;
      opacity: 0;
      width: 12px;
      height: 12px;
      margin: auto;
      position: absolute;
      inset: -100%;

      transition: var(--transition-fast);
    }
  }

  &:hover &__input-custom {
    border-color: var(--color-main);
  }

  &__input-original:checked + &__input-custom {
    border-color: var(--color-main);
  }

  &__input-original:checked + &__input-custom:after {
    opacity: 1;
  }

  &:hover &__input-original:checked + &__input-custom {
    border-color: var(--color-dark-orange);
  }

  &__label {
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    color: var(--color-gray-800);
    margin-left: 8px;
    position: relative;
    top: 1px;
  }

  &--small {
    .radio__input-custom {
      width: 16px;
      height: 16px;

      &:after {
        width: 10px;
        height: 10px;
      }
    }

    .radio__label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &--disabled {
    pointer-events: none;

    .radio__input-custom,
    .radio__input-original:checked + .radio__input-custom {
      background-color: var(--color-gray-200);
      border-color: var(--color-gray-200);
    }

    .cradio__label {
      color: var(--color-gray-500);
    }
  }
}