.accordion-item {
  border-bottom: 1px solid var(--color-gray-300);
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0px;
    cursor: pointer;
    user-select: none;

    &__chevron {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-left: 16px;
      transition: var(--transition-fast);
    }
  }

  &__footer {
    opacity: 0;
    min-height: 0px;
    max-height: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: var(--transition-fast);
  }

  &--active {
    .accordion-item__header {
      &__chevron {
        transform: rotateX(180deg);
      }
    }

    .accordion-item__footer {
      opacity: 1;
      max-height: fit-content;
      padding-bottom: 24px;
    }
  }
}