.container {
  display: flex;

  &__filters {
    border-right: 1px solid var(--color-gray-300);
    width: 304px;
    padding-right: 32px;
    margin-right: 32px;
    position: relative;

    &__item {
      &:not(:nth-child(1)) {
        margin-top: 46px;
      }

      &__title {
        margin-bottom: 16px;
      }

      &__search-input {
        margin-bottom: 16px;
      }

      &__expand-button {
        display: flex;
        align-items: center;
        margin-top: 16px;
        cursor: pointer;

        &__text {
          color: var(--color-main);
        }

        &__icon {
          margin-left: 8px;
          transition: var(--transition-fast);

          svg {
            fill: var(--color-main);
          }
        }

        &--active {
          &__icon {
            transform: rotateZ(180deg);
          }
        }
      }
    }
  }

  &__cards {
    width: calc(100% - 304px - 32px);
    position: relative;
  }

  &__bottom {
    &__button-group {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      &__button {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-wrap: wrap;

    &__filters {
      border-right: initial;
      border-bottom: 1px solid var(--color-gray-300);
      width: 100%;
      padding-right: initial;
      padding-bottom: 32px;
      margin-right: initial;
    }

    &__cards {
      width: 100%;
      margin-top: 32px;
    }
  }
}
