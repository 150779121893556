.billing {
  &__back-link {
    margin-bottom: 32px;
  }

  .text {
    margin-top: 16px;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-section {
    &__form {
      margin-top: 32px;
    }

    &__submit {
      margin-top: 32px;
    }

    &__filled-data {
      margin-top: 32px;
    }
  }

  .recent-transactions {
    margin-top: 104px;

    &__simulation {
      margin-top: 64px;

      &__text {
        margin-top: 16px;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      }

      &__bg-el {
        position: absolute;
        top: 0px;
        right: 0px;
      }

      &__switch-toggle-container {
        display: flex;
        align-items: center;

        &__label {
          font-weight: 700;
          margin-left: 16px;
        }
      }
    }

    &__table {
      margin-top: 32px;
    }
  }
}

.table-container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  overflow: hidden;
}

.table {
  text-align: left;
  border-spacing: 0;
  width: calc(100% + 2px);
  position: relative;
  left: -1px;
  overflow: hidden;

  thead {
    background-color: var(--color-gray-50);
  }

  th {
    padding: 14px 16px 14px 16px;

    .table__th-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &--can-sort {
        cursor: pointer;
      }
    }
  }

  &__sort-icon {
    max-width: 16px;
    flex: 1 0 auto;
    margin-left: 16px;
    position: relative;
    top: 2px;

    :global(.up-part) {
      transition: var(--transition-fast);
    }

    :global(.down-part) {
      transition: var(--transition-fast);
    }

    &--asc {
      :global(.up-part) {
        fill: var(--color-main);
      }
    }

    &--desc {
      :global(.down-part) {
        fill: var(--color-main);
      }
    }
  }

  td {
    border: 1px solid var(--color-light-gray-3);

    padding: 10px 16px;
  }

  &__cell {
    &--alert-state-true {
      color: var(--color-red-400);
    }
  }
}

@media screen and (max-width: 1279px) {
  .billing {
    .recent-transactions {
      &__simulation {
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .table-container {
    overflow: auto;
  }

  .table {
    overflow: hidden;
  }
}
