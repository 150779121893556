.what-is-pop-up {
  text-align: center;

  :global(.pop-up) {
    overflow: hidden;
  }

  :global(.pop-up__body) {
    height: 510px;
  }

  &__bg {
    object-fit: cover;
    object-position: top center;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    pointer-events: none;
    transition: var(--transition-fast);
  }

  &--not-first-slide {
    .what-is-pop-up__bg {
      object-position: 100% 30%;
      width: calc(100% - 32px - 32px);
      height: 200px;
      top: 88px;
      left: 32px;
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    height: 100%;

    &__inner {
      position: relative;
    }

    &--not-first {
      display: block;
    }
  }

  &__steps {
    color: var(--color-gray-700);
    margin-top: 32px;
  }

  &__sub-title {
    color: var(--color-main);
    margin-bottom: 16px;
  }

  &__title {
    color: var(--color-white);
  }

  &__title-2 {
    margin-top: 16px;
  }

  &__text {
    max-width: 616px;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    position: relative;
    top: -16px;

    &__icon {
      font-size: 80px;
    }
  }

  &__progress-bar {
    border-radius: 12px;
    background-color: var(--color-gray-300);
    width: 200px;
    height: 8px;
    margin: 0px auto;
    position: absolute;
    top: 48px;
    right: 0px;
    left: 0px;

    &__indicator {
      border-radius: 12px;
      background-color: var(--color-main);
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: var(--transition-fast);
    }
  }

  &__footer-button-list {
    display: flex;

    &__item {
      &:not(:nth-last-child(1)) {
        flex: 0 0 auto;
        margin-right: 16px;
      }

      &:nth-child(2) {
        width: 100%;

        > * {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .what-is-pop-up {
    text-align: left;

    :global(.pop-up) {
      border-radius: initial !important;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
      overflow: auto;
    }

    :global(.pop-up__body) {
      height: initial;
      flex-grow: 1;
    }

    &__bg {
      border-radius: initial;
    }

    &--not-first-slide {
      .what-is-pop-up__bg {
        border-radius: 8px;
        object-position: 100% 30%;
        width: calc(100% - 16px - 16px);
        height: 104px;
        top: 88px;
        left: 16px;
      }
    }

    &__icon-container {
      height: 104px;
      top: 0px;

      &__icon {
        font-size: 56px;
      }
    }

    &__progress-bar {
      top: 33px;
    }

    &__footer-button-list {
      display: flex;
      flex-wrap: wrap;

      &__item {
        width: 100%;

        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        & > * {
          width: 100%;
        }
      }
    }

    &--not-first-slide {
      .what-is-pop-up__footer-button-list {
        flex-wrap: nowrap;
        overflow: hidden;

        &__item {
          width: initial;
          flex: 1 0 auto;

          &:not(:nth-child(1)) {
            margin-top: initial;
          }
        }
      }
    }
  }
}
