.button {
  background: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: var(--transition-fast);

  &__icon {
    svg {
      fill: var(--color-main);
      transition: var(--transition-fast);
    }
  }

  &--color-main {
    &:hover {
      background-color: var(--color-main);
    }
  }

  &--color-blank {
    background: initial;
    border: 1px solid rgba(255, 255, 255, 0.25);

    .button__icon {
      svg {
        fill: var(--color-white);
      }
    }

    &:hover {
      background-color: var(--color-white);

      .button__icon {
        svg {
          fill: var(--color-space-blue-1);
        }
      }
    }
  }

  &--color-white {
    background: var(--color-white);
    border: 1px solid var(--color-gray-300);

    .button__icon {
      svg {
        fill: var(--color-main);
      }
    }

    &:hover {
      background-color: var(--color-main);
      border-color: var(--color-main);

      .button__icon {
        svg {
          fill: var(--color-white);
        }
      }
    }
  }
}