.section {
  :global(.background) {
    
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-top: 64px;
    position: relative;
    left: -8px;

    &--centered-true {
      justify-content: center;
    }

    &__item {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .section {
    overflow: hidden;
  }
}