.footer {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  position: sticky;
  bottom: 0px;
  z-index: 99;

  :global(.wrapper) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .content {
    .part-list {
      display: flex;
      justify-content: space-between;
      
      &__left {
        margin-right: 24px;
      }

      &__right {
        display: flex;

        .skip-button {
          margin-right: 16px;
        }
      }
    }
  }
}