.section {
  :global(.wrapper) {
    padding-top: 40px;
  }

  :global(.background) {
    background: linear-gradient(180deg, #eaeaf0 0%, #f1f1f4 100%), var(--Background-Neutral-Primary, #f1f1f4);
  }

  .content {
    min-height: calc(100vh - 105px - 64px - 64px);

    .logo {
      display: flex;
      margin-bottom: 64px;
      position: relative;

      svg {
        width: 193px;
      }
    }

    .title {
      margin-bottom: 64px;
    }

    .input-description {
      color: var(--Text-onLight-Neutral-Secondary, #5e617b);
      margin-top: 4px;
    }

    .form {
      &__part {
        display: flex;
        justify-content: space-between;

        &-location {
          margin-top: 128px;
        }

        &-role {
          margin-top: 128px;
        }

        &__left {
          width: calc(415px + 80px);
          padding-right: 80px;
        }

        &__right {
          width: 753px;
        }

        &__title {
          max-width: 415px;
        }

        &__text {
          max-width: 335px;
          margin-top: 16px;
        }
      }
    }

    .outline-warning {
      border: 1px solid var(--color-red-400);
      border-radius: 8px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      position: absolute;
      top: -4px;
      left: -4px;
      pointer-events: none;
    }
  }

  .bottom-part {
    // background-color: var(--color-white);
    // border-top: 1px solid var(--color-gray-300);
    position: relative;
    bottom: 0px;
    left: 0px;

    :global(.wrapper) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &__content {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    :global(.wrapper) {
      padding-top: 24px;
      padding-bottom: 16px;
    }

    .content {
      .logo {
        justify-content: flex-start;
        margin-bottom: 64px;
      }

      .form {
        &__part {
          display: flex;
          flex-wrap: wrap;

          &-role {
            margin-top: 48px;
          }

          &-submit {
            margin-top: 48px;
          }

          &__left {
            width: 100%;
            padding-right: initial;
          }

          &__right {
            width: 100%;
            margin-top: 24px;
          }

          &__title {
            max-width: initial;
          }

          &__text {
            max-width: initial;
            margin-top: 16px;
          }

          &__submit {
            display: flex;
            justify-content: center;
            width: 100%;

            & > * {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
