.pop-up {
  :global(.pop-up) {
    overflow: hidden;
  }

  :global(.pop-up__body) {
    // height: 510px;
  }

  &__icon-container {
    background-color: var(--Fill-Brand-Orange-Default);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  &__text {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .pop-up {
    &__icon-container {
      top: 16px;
      left: 16px;
    }
  }
}
