.overlay {
  opacity: 0;
  background: linear-gradient(131deg, #23286f 26.12%, #010433 102.86%), #292e6d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 222;
  pointer-events: none;
}
