.checkbox {
  background-color: var(--Background-Neutral-Container, #fff);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--Border-Neutral-Default, #dadbe2);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  // height: 156px;
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: var(--transition-fast);

  &:after {
    content: '';
    border-radius: 16px;
    border: 2px solid var(--color-main);
    opacity: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    transition: var(--transition-fast);
  }

  &__input-original {
    display: none;
  }

  &__input-custom {
    background: var(--color-white);
    border: 2px solid var(--color-gray-300);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;

    transition: var(--transition-fast);

    &:after {
      content: '';
      background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1583 2.825C13.6139 2.36939 13.6139 1.63069 13.1583 1.17508C12.7027 0.719472 11.964 0.71947 11.5084 1.17508L5 7.68346L2.49162 5.17508C2.03601 4.71947 1.29732 4.71947 0.841709 5.17508C0.386097 5.63069 0.386097 6.36939 0.841709 6.825L4.17504 10.1583C4.63065 10.6139 5.36935 10.6139 5.82496 10.1583L13.1583 2.825Z" fill="white"/></svg>');
      background-size: 100% 100%;
      opacity: 0;
      width: 16px;
      height: 12px;
      margin: auto;
      position: absolute;
      inset: -100%;

      transition: var(--transition-fast);
    }
  }

  &:hover {
    background-color: var(--color-gray-50);
  }

  &:hover &__input-custom {
    border-color: var(--color-main);
  }

  &__input-original:checked + &__input-custom {
    background-color: var(--color-main);
    border-color: var(--color-main);
  }

  &__input-original:checked + &__input-custom:after {
    opacity: 1;
  }

  &:hover &__input-original:checked + &__input-custom {
    background-color: var(--color-dark-orange);
    border-color: var(--color-dark-orange);
  }

  &__label {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-gray-800);
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    transition: var(--transition-fast);
  }

  &--disabled {
    // background-color: rgba(255, 0, 0, 0.033);
    filter: grayscale(50%);
    opacity: 0.5;
    pointer-events: none;

    .checkbox__input-custom,
    .checkbox__input-original:checked + .checkbox__input-custom {
      background-color: var(--color-gray-200);
      border-color: var(--color-gray-200);

      &:after {
        background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1583 2.825C13.6139 2.36939 13.6139 1.63069 13.1583 1.17508C12.7027 0.719472 11.964 0.71947 11.5084 1.17508L5 7.68346L2.49162 5.17508C2.03601 4.71947 1.29732 4.71947 0.841709 5.17508C0.386097 5.63069 0.386097 6.36939 0.841709 6.825L4.17504 10.1583C4.63065 10.6139 5.36935 10.6139 5.82496 10.1583L13.1583 2.825Z" fill="%23909DAD"/></svg>');
      }
    }

    .checkbox__label {
      color: var(--color-gray-500);
    }
  }

  &--active {
    &:after {
      opacity: 1;
    }

    .checkbox__label {
      color: var(--color-main);
    }
  }
}
