.section {
  color: var(--color-white);

  &--mark-tag-color-blue {
    mark {
      color: var(--color-light-blue);
    }
  }

  .block-list {
    display: flex;
    align-items: center;
    position: relative;

    &__item {
      position: relative;

      &:nth-child(1) {
        flex: 1 0 auto;
        max-width: 640px;
      }
    }
  }

  .title {
    &__mark {
      color: var(--color-cyan);
      position: relative;

      &__line {
        background-color: var(--color-cyan);
        width: 100%;
        height: 6px;
        position: absolute;
        bottom: -10px;
        left: 0px;
      }
    }
  }

  .sec-title {
    margin-bottom: 8px;
  }

  .breadcrumbs {
    margin-bottom: 24px;
  }

  .text {
    margin-top: 24px;

    p:not(:nth-child(1)) {
      margin-top: 40px;
    }
  }

  .element-after-text {
    margin-top: 50px;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-top: 64px;
    position: relative;
    left: -8px;

    &__item {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  &.var-0 {
    :global(.background) {
      background-image: conic-gradient(from 179.59deg at 72.22% 48.08%, #23286f 0deg, #010433 360deg);
      background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);
    }

    .bg-el {
      width: 100vw;
      position: absolute;
      right: 0px;
      bottom: 0px;

      svg {
        display: block;
        width: 100%;
      }
    }

    :global(.wrapper) {
      display: flex;
      align-items: center;
      min-height: 100vh;
      padding-top: var(--header-height);
      padding-bottom: initial;
      margin-top: calc(0px - var(--header-height));
    }
  }

  &.var-1,
  &.var-1-a,
  &.var-1-b,
  &.var-1-c,
  &.var-1-c-2,
  &.var-1-c-3,
  &.var-1-c-4 {
    overflow: hidden;

    :global(.background) {
      background-color: var(--color-space-blue-1);
      background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);

      .illustration {
        position: absolute;
        right: 0px;
      }
    }

    .bg-el {
      width: 100vw;
      position: absolute;
      right: 0px;
      bottom: 0px;

      svg {
        display: block;
        width: 100%;
      }
    }

    :global(.content) {
      padding-top: 3vh;
      padding-bottom: calc(1vh + 6.65vw);

      .block-list {
        &__item {
          &:nth-child(2) {
          }
        }
      }

      .illustration {
        display: flex;
        justify-content: flex-end;
        width: 128%;
        position: relative;
        // top: -32%;
        left: 64px;

        & > * {
          max-width: initial;
          // position: absolute;
        }
      }

      .image-element {
        border-radius: 8px;
        // width: 100%;
        display: inline-block;
        position: relative;
        overflow: hidden;
      }

      .button-group {
        margin-top: 24px;
      }
    }
  }

  &.var-1-a,
  &.var-1-b {
    color: var(--color-black);

    :global(.background) {
      background: initial;
      background-color: var(--color-white);
    }

    .text {
      color: var(--color-gray-800);
    }
  }

  &.var-1-b {
    :global(.background) {
      background: initial;
      background-color: var(--color-gray-50);
    }

    .block-list {
      justify-content: space-between;
      // align-items: initial;

      &__item {
        &:nth-child(1) {
          max-width: 752px;
          margin-right: 24px;
        }

        &:nth-child(2) {
          max-width: 416px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  &.var-1-c {
    :global(.background) {
      background: var(--color-space-blue-1);
    }

    :global(.content) {
      .button-group {
        margin-top: 64px;
      }
    }
  }

  &.var-1-c-2 {
    :global(.background) {
      background: var(--color-space-blue-1);
    }

    .block-list {
      justify-content: space-between;

      &__item {
        &:nth-child(1) {
          max-width: 528px;
          margin-right: 24px;
        }
      }
    }
  }

  &.var-1-c-3 {
    :global(.content) {
      .button-group {
        margin-top: 64px;
      }
    }
  }

  &.var-1-c-4 {
    :global(.background) {
      background: linear-gradient(180deg, var(--color-space-blue-1) 0.05%, #010534 99.68%);
    }

    :global(.content) {
      .button-group {
        margin-top: 64px;
      }
    }
  }

  :global(.background.background--color-neutral-primary) {
    background-color: var(--Background-Neutral-Primary);
  }

  :global(.background.background--color-neutral-secondary) {
    background-color: var(--Background-Neutral-Secondary);
  }

  .illustration {
    &--position-var-a {
      width: 100% !important;
      left: initial !important;
    }
  }
}

.section + :global(.section-divider) {
  margin-top: -6.65vw;
}

@media screen and (max-width: 1279px) {
  .section {
    .block-list {
      flex-wrap: wrap;

      &__item {
        &:nth-child(1) {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &.var-0 {
      :global(.background) {
        background: initial;
        background-color: var(--color-space-blue-1);
        background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);

        .bg-el {
          display: none;
        }
      }

      :global(.wrapper) {
        min-height: initial;

        :global(.content) {
          padding-top: 64px;
          padding-bottom: 64px;
        }
      }
    }

    &.var-1,
    &.var-1-a,
    &.var-1-b,
    &.var-1-c,
    &.var-1-c-2,
    &.var-1-c-3,
    &.var-1-c-4 {
      :global(.content) {
        padding-top: 64px;
        padding-bottom: 64px;

        .block-list {
          &__item {
            &:nth-child(2) {
              width: 100%;
              margin-top: 64px;
            }
          }
        }

        .illustration {
          width: 100%;
          top: initial;
          left: initial;

          & > * {
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }

      :global(.wrapper) {
        padding-top: var(--header-height);
        padding-bottom: initial;
        margin-top: calc(0px - var(--header-height));
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    .button-group {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 16px);
      margin-top: 64px;
      position: relative;
      left: -8px;

      &__item {
        width: 100%;
        margin-right: initial;
        margin-left: initial;

        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        > * {
          width: 100%;
        }
      }
    }
  }
}
