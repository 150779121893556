.container {
  &--type-default {
    opacity: 0.9;
  }

  &--type-overlay {
    background-color: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% + 24px + 24px);
    height: calc(100% + 24px + 24px);
    max-height: calc(100% + 24px + 24px);
    padding-top: 64px;
    position: absolute;
    top: -24px;
    left: -24px;
  }

  .text {
    position: sticky;
    top: 128px;
    left: 0px;
  }

  .text-2 {
    text-align: center;
    display: block;
    width: 100%;
  }
}
