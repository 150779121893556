.header {
  background-color: var(--color-white);
  position: sticky;
  top: 0px;
  z-index: 99;

  .progress {
    background-color: var(--color-gray-200);
    height: 8px;
    position: relative;

    &__current {
      background-color: var(--color-main);
      width: 0%;
      height: 100%;
      transition: var(--transition-fast);
    }
  }

  :global(.wrapper) {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .content {
    .part-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__left {
        min-width: calc(154px + 24px);
        margin-right: 24px;
      }

      &__right {
        display: flex;
        align-items: center;

        .rocket-fuel {
          margin-left: 24px;
        }
      }
    }
  }
}
