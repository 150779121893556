.main-template {
  &--startup-animation-active {
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .overlay {
    background-color: var(--color-white);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
  }

  .children {
    position: relative;
    z-index: 66;
  }

  .startup-section {
    position: relative;

    &--main-pages {
      position: absolute;
      width: 100%;
      z-index: 55;
    }
  }
}
