.notifications {
  .text {
    margin-top: 16px;
  }

  .list {
    margin-top: 32px;

    &__item {
      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__text {
        margin-top: 16px;
      }
    }
  }
}