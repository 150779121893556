.content-section {
  color: var(--color-black);

  &__block-list {
    display: flex;
    position: relative;

    &__item-1 {
      width: 304px;
      height: 100%;
      margin-right: 32px;
      position: sticky;
      top: calc(var(--header-height) + 24px);
    }

    &__item-2 {
      width: calc(100% - 304px - 32px);
    }
  }

  &__project-details {
    border-radius: 8px;
    border: 1px solid var(--color-gray-300);
    background: var(--color-gray-50);

    &__main-title {
      padding: 24px;
    }

    &__item-list {
      &__item {
        padding: 24px;

        &__title {
          color: var(--color-main);
        }

        &__text {
          margin-top: 16px;
        }

        &:nth-last-child(1) {
          background: var(--color-gray-200);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content-section {
    &__block-list {
      flex-wrap: wrap;
  
      &__item-1 {
        width: 100%;
        height: initial;
        margin-right: initial;
        position: relative;
        top: initial;
      }
  
      &__item-2 {
        width: 100%;
        margin-top: 64px;
      }
    }
  }
}