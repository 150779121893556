.breadcrumbs {
  ol {
    display: flex;
    align-items: center;
    position: relative;
    left: -11px;

    li {
      padding-right: 11px;
      padding-left: 11px;

      a {
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        color: var(--color-main);
        position: relative;

        &:before {
          content: '';
          background-image: url('data:image/svg+xml,<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 1L1 11" stroke="%23DBE0E7" stroke-linecap="round"/></svg>');
          background-size: 100% 100%;
          width: 7px;
          height: 12px;
          margin: auto 0px;
          position: absolute;
          top: -100%;
          bottom: -100%;
          right: -15px;
        }

        &:after {
          content: '';
          background-color: var(--color-main);
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 4px;
          left: 0px;
          transition: var(--transition-fast);
        }

        &:hover:after {
          opacity: 0;
        }
      }

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        color: var(--color-gray-800);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .breadcrumbs {
    ol {
      align-items: flex-start;

      li {
        flex: 0 0 auto;
      }
    }
  }
}
