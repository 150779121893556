.card {
  position: relative;
  overflow: hidden;

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}