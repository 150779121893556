.general {
  overflow: hidden;
}

.section {
  color: var(--color-white);
  padding-bottom: 741px;
  position: relative;

  mark {
    color: var(--Text-Brand-Cyan-Default);
  }

  .bg-el {
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }

  :global(.wrapper) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .dude {
    min-width: 100vw;
    width: 2560px;
    height: 741px;
    margin: 0px auto;
    position: absolute;
    right: -100%;
    left: -100%;
    pointer-events: none;
    z-index: 55;

    > * {
      display: block;
      width: 100%;
      max-height: 741px;
    }
  }

  .middle-part {
    margin-top: 104px;
  }

  .text-4 {
    text-align: center;
  }

  .text-2 {
    margin-top: 40px;
  }

  .text-3 {
    margin-top: 8px;
  }

  .block-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;

    &__item {
      width: calc(50% - 8px);
      position: relative;
      overflow: hidden;

      &__icon-on-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 208px;
        height: 208px;
        position: absolute;
        bottom: -50px;
        right: -50px;

        & > * {
          opacity: 0.033;
          width: 90%;
          height: auto;
          max-height: 90%;
        }
      }

      &__icon {
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;

        & > * {
          max-width: 95%;
          max-height: 95%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    .bg-el {
      max-width: initial;
    }

    :global(.wrapper) {
      padding-bottom: 0px;
    }

    .dude {
      right: calc(-1000% - 800px);
      left: -1000%;
    }

    .block-list {
      &__item {
        width: 100%;

        &__icon-on-bg {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 208px;
          height: 208px;
          position: absolute;
          bottom: -50px;
          right: -50px;

          & > * {
            opacity: 0.033;
            width: 90%;
            height: auto;
            max-height: 90%;
          }
        }
      }
    }
  }
}
