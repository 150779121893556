.react-tooltip {
  color: var(--Neutral-Primary) !important;
  background-color: var(--color-white) !important;
  border-radius: 4px !important;
  padding: 8px 16px !important;
  box-shadow: var(--shadow-xs) !important;
}

.react-tooltip__show {
  opacity: 1 !important;
}
