.close-button {
  background-color: initial;
  border: initial;
  outline: initial;
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: initial;
  cursor: pointer;

  > * {
    fill: var(--color-black);
    display: block;
    width: 100%;
  }
}