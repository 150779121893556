.section {
  :global(.wrapper) {
    padding-top: 40px;
    padding-bottom: initial;
  }

  :global(.background) {
    background: linear-gradient(180deg, #eaeaf0 0%, #f1f1f4 100%), var(--Background-Neutral-Primary, #f1f1f4);
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    pointer-events: none;

    &__current {
      background-color: var(--color-main);
      height: 100%;
      position: absolute;
      transition: var(--transition-fast);
    }
  }

  .content {
    min-height: calc(100vh - 105px - 64px - 64px);

    .logo {
      display: flex;
      margin-bottom: 64px;
      position: relative;

      svg {
        width: 193px;
      }
    }

    .title {
      // margin-bottom: 64px;
    }

    .number {
      border-radius: 20px;
      border: 2px solid var(--Border-Neutral-Default, #dadbe2);
      background: var(--Background-Neutral-Container, #fff);
      /* shadow-xs */
      box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 64px;
      width: 64px;
      height: 64px;
      margin-right: 48px;

      &__text {
        color: var(--Text-Brand-Orange-Default, #e24e29);
        text-align: center;
        font-size: var(--Heading-3-font-size, 32px);
        font-weight: 500;
        line-height: var(--Heading-3-line-height, 150%);
        letter-spacing: -0.32px;
      }
    }

    .text-2 {
      margin-top: 16px;
    }

    .link-like {
      font-weight: 700;
      text-decoration: underline;
      color: var(--color-main);
      cursor: pointer;
    }

    .button {
      margin-top: 32px;
    }

    .ill-container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 672px;
      height: 320px;
      max-height: 320px;
      padding-right: 32px;
      padding-left: 32px;

      & > * {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .line {
      background-color: var(--Border-Neutral-Default, #dadbe2);
      width: 2px;
      height: 100%;
      position: absolute;
      top: calc(64px + 100px);
      left: 32px;
    }

    .item-list {
      position: relative;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 64px;
        position: relative;

        &__part-1 {
          display: flex;
          width: calc(416px + 48px + 64px + 10px);
          position: relative;
        }

        &__part-2 {
          max-width: calc(100% - 416px - 48px - 64px - 10px);
          margin-left: 40px;
        }

        &:nth-last-child(1) {
          .line {
            display: none;
          }
        }
      }
    }
  }

  .footer {
    background: linear-gradient(180deg, rgba(241, 241, 244, 0) 0%, #f1f1f4 100%);
    padding-top: 80px;
    padding-bottom: 24px;
    position: sticky;
    bottom: 0px;
    left: 0px;
    z-index: 66;

    .flex-container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.equity-pop-up {
  &__icon-container {
    background-color: var(--Background-Brand-Primary, #fef6f0);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  &__title {
    margin-top: 32px;
  }

  &__text {
    margin-top: 16px;
  }
}

.calc-pop-up {
  &__icon-container {
    background-color: var(--Background-Brand-Primary, #fef6f0);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  &__title {
    margin-top: 32px;
  }

  &__text {
    margin-top: 16px;
  }

  &__widget {
    margin-top: 32px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1279px) {
  .equity-pop-up {
    &__icon-container {
      top: 24px;
      left: 24px;
    }
  }

  .calc-pop-up {
    &__icon-container {
      top: 24px;
      left: 24px;
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    :global(.wrapper) {
      padding-top: 40px;
      padding-bottom: initial;
    }

    .content {
      .number {
        margin-right: 24px;
        margin-left: -12px;
      }

      .text-2 {
        margin-top: 16px;
      }

      .button {
        margin-top: 32px;
      }

      .ill-container {
        justify-content: flex-end;
        max-width: 100%;
        width: calc(100% - 40px);
        height: auto;
        max-height: 320px;
        padding-right: initial;
        padding-left: initial;
        margin-top: 64px;
        position: relative;
        left: 40px;

        & > * {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .line {
        background-color: var(--Border-Neutral-Default, #dadbe2);
        width: 2px;
        height: 100%;
        position: absolute;
        top: calc(64px + 100px - 40px);
        left: 20px;
      }

      .item-list {
        position: relative;

        .item {
          flex-wrap: wrap;
          padding-top: 64px;
          padding-bottom: 64px;
          position: relative;

          &__part-1 {
            display: flex;
            width: 100%;
            position: relative;
          }

          &__part-2 {
            width: 100%;
            max-width: 100%;
            margin-left: initial;
          }

          &:nth-last-child(1) {
            .line {
              display: none;
            }
          }
        }
      }
    }

    .footer {
      padding-top: 40px;
      padding-bottom: 12px;
    }
  }

  .equity-pop-up {
    &__icon-container {
      top: 12px;
      left: 12px;
    }
  }

  .calc-pop-up {
    &__icon-container {
      top: 12px;
      left: 12px;
    }
  }
}
