.element {
  border: 2px solid var(--color-main);
  border-radius: 16px;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  position: absolute;
  top: -12px;
  left: -12px;
  pointer-events: none;
  animation: 0.2s linear infinite alternate flash;
}

@keyframes flash {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
