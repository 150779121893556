.list {
  display: flex;
  align-items: center;
  position: relative;

  &__item {
    position: relative;

    &:not(:nth-last-child(1)) {
      margin-right: 32px;

      &:after {
        content: '';
        background-color: var(--color-gray-300);
        width: 16px;
        height: 1px;
        margin: auto 0px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: calc(100% + 8px);
      }
    }
  }
}
