.account-header {
  padding-bottom: 6.65vw;

  & + :global(.section-divider) {
    margin-top: -6.65vw;
  }

  &__bg {
    background-image: url('../../../../../asset/images/account_header_bg.jpg');

    background-size: cover;
    background-position: top right;
  }

  :global(.content) {
    color: var(--color-white);
    position: relative;
  }

  .block-list {
    display: flex;
    justify-content: space-between;

    &__item-1 {
      display: flex;
      margin-right: 24px;
    }
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 24px;
    margin-right: 32px;
    position: relative;
    overflow: hidden;
  }

  .account-info {
    &__name-container {
      display: flex;

      &__text {
      }

      &__edit-button {
        margin-left: 24px;
      }
    }

    &__location-container {
      display: flex;
      align-items: center;
      margin-top: 16px;

      &__icon {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .account-header {
    .block-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: initial;

      &__item-1 {
        flex-wrap: wrap;
        width: 100%;
        margin-right: initial;
      }

      &__item-2 {
        width: 100%;
        margin-top: 48px;
      }
    }

    .avatar {
      margin-right: initial;
    }

    .account-info {
      width: 100%;
      margin-top: 24px;

      &__name-container {
        justify-content: space-between;
      }
    }
  }
}
