.card {
  &__header {
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }

  &__body {
    &__text {
      margin-top: 16px;
    }

    &__button {
      margin-top: 16px;
    }
  }

  &.var-1 {
    .card__header {
      display: block;

      &__icon {
        border-radius: var(--border-radius-3);
        width: 45px;
        height: 45px;
        margin-right: initial;
        margin-bottom: 16px;
      }

      &__title {
        transition: var(--transition-fast);
      }
    }

    .card__body {
      &__button {
        opacity: 0;
        transform: translateY(10px);
        transition: var(--transition-fast);
      }
    }

    &.var-1:hover {
      .card__header {
        &__title {
          color: var(--color-main);
        }
      }

      .card__body {
        &__button {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }

  &--color-white {
    background-color: var(--color-space-blue-2);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: var(--border-radius-3);
    color: var(--color-white);
    padding: 32px;
  }
}
