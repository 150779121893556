.container {
  text-align: center;
  margin-bottom: 64px;

  &--text-align-left {
    text-align: left;
  }

  .sec-title {
    color: var(--color-main);
    margin-bottom: 8px;
  }

  .text {
    margin-top: 24px;
  }
}