@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-black-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-heavy-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-heavy-webfont.woff') format('woff');
    font-weight: 850;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-bold-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-semibold-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-semibold-webfont.woff') format('woff');
    font-weight: 650;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-medium-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-regular-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-book-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-book-webfont.woff') format('woff');
    font-weight: 350;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-light-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../fonts/axiforma/axiforma-thin-webfont.woff2') format('woff2'),
         url('../fonts/axiforma/axiforma-thin-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}