.section {
  .row-list {
    &__item {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &__col:nth-child(1) {
        padding-right: 64px;
      }

      &__col:nth-child(2) {
        flex: 0 0 auto;
      }
    }

    &__item:nth-child(2) {
      display: block;
      margin-top: 64px;
    }
  }

  .title {
    color: var(--color-black);
  }

  .text {
    color: var(--color-gray-800);
    margin-top: 24px;
  }

  &.var-1 {
    .row-list {
      &__item {
        align-items: flex-start;
      }
    }

    .button-2 {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .section {
    .row-list {
      &__item {
        flex-wrap: wrap;
  
        &__col:nth-child(1) {
          width: 100%;
          padding-right: initial;
        }
  
        &__col:nth-child(2) {
          width: 100%;
          margin-top: 40px;
        }
      }
  
      &__item:nth-child(2) {
        display: block;
        margin-top: 64px;
      }
    }
  }
}