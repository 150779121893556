.burger {
  background: none;
  outline: none;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: initial;
  margin: initial;
  cursor: pointer;

  &__line-container {
    position: relative;
  }

  &__line {
    border-radius: 24px;
    width: 26px;
    height: 2px;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      background-color: var(--color-white);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &:not(:nth-child(1)) {
      margin-top: 6px;
    }
  }

  &--active {
    .burger__line {
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      &:nth-child(2) {
        visibility: hidden;
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  &--color-dark {
    .burger__line {
      &:before {
        background-color: var(--color-black);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .burger {
    width: 24px;
    height: 24px;

    &__line {
      width: 20px;
      height: 2px;

      &:not(:nth-child(1)) {
        margin-top: 4px;
      }
    }

    &--active {
      .burger__line {
        &:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
        }
  
        &:nth-child(2) {
          visibility: hidden;
        }
  
        &:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
        }
      }
    }
  }
}