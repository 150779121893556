.pop-up-text {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__content {
  }

  &__icon {
    margin-left: 8px;
    position: relative;
    top: -3px;

    > * {
      display: block;
    }
  }
}
