.template {
  background-color: var(--Background-Neutral-Primary);
  min-height: 100vh;

  .main {
    min-height: calc(100vh - 106px - 96px);

    :global(.wrapper) {
      padding-top: 56px;
      padding-bottom: 84px;
    }

    .content {
      .part-list {
        display: flex;
        justify-content: space-between;

        &__main {
          width: 100%;
          max-width: 864px;
          margin-right: 40px;

          &__outlet {
            width: 100%;
            margin-top: 32px;
          }
        }

        &__side {
          max-width: 304px;
          position: relative;

          .info {
            width: 100%;

            &__title {
              color: var(--color-main);
            }

            &__text {
              margin-top: 16px;
            }

            &__button {
              margin-top: 24px;

              & > * {
                width: 100%;
              }
            }
          }

          .fuel-info {
            margin-top: 24px;

            &__text {
              margin-bottom: 8px;
            }

            &__number {
              margin-bottom: -8px;
            }

            &__icon {
              fill: rgba(255, 255, 255, 0.25);
              width: 58px;
              position: absolute;
              top: 32px;
              right: 24px;
            }
          }
        }
      }

      .sec-title {
        color: var(--color-main);
        margin-bottom: 8px;
      }

      .sec-title-2 {
        margin-top: 16px;
      }
    }
  }

  &--edit-role {
    :global(.wrapper) {
      padding: initial !important;
    }

    .part-list__main__outlet {
      margin-top: initial !important;
    }

    .part-list__main {
      max-width: 100% !important;
      margin-right: initial !important;
    }
  }
}

.skip-pop-up {
  &__footer-button-list {
    display: flex;
    justify-content: flex-end;

    &__item {
      &:not(:nth-last-child(1)) {
        margin-right: 16px;
      }
    }
  }

  &__fuel-tile {
    border-radius: 4px;
    border: 1px solid var(--color-gray-300);
    background: var(--color-gray-50);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin-top: 24px;

    &__icon {
      color: var(--color-main);
      margin-right: 24px;
    }

    &__text {
      color: var(--color-main);
    }
  }

  &__text-2 {
    margin-top: 24px;
  }

  &__text-3 {
    margin-top: 24px;
  }
}

.section {
  &:not(:nth-child(1)) {
    margin-top: 64px;
    position: relative;

    &:after {
      content: '';
      background-color: var(--color-gray-300);
      width: 100%;
      height: 1px;
      position: absolute;
      top: -32px;
      left: 0px;
    }
  }

  .title {
    color: var(--color-main);
    margin-bottom: 24px;
  }

  .special-label {
    display: flex;
    align-items: center;

    &__text {
      margin-right: 8px;
    }
  }

  .tip-for-input {
    color: var(--color-gray-700);
    margin-top: 4px;
  }

  &__title {
    color: var(--color-main);
    margin-bottom: 24px;
  }

  &__title-2 {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__text {
      margin-right: 8px;
    }
  }

  &__text {
    margin-bottom: 24px;
  }

  &__radio-list {
    columns: 3;
  }

  &__checkbox-list {
    columns: 3;
  }

  &__form-layout {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    &__input {
      width: 100%;

      &:not(:nth-child(1)) {
        // margin-top: 24px;
      }
    }

    &--your-company {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .section__form-layout__input {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          width: calc(50% - 8px);
        }

        &:nth-child(3) {
          width: calc(50% - 8px);
        }
      }
    }

    &--your-former-company {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .section__form-layout__input {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          width: 100%;
        }

        &:nth-child(3) {
          width: calc(50% - 8px);
        }

        &:nth-child(4) {
          width: calc(50% - 8px);
        }
      }
    }
  }
}

.rf-pop-up {
  &__icon-container {
    background-color: var(--Background-Brand-Primary, #fef6f0);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  &__title {
    margin-top: 32px;
  }

  &__text {
    margin-top: 16px;
  }

  &__button {
    float: right;
  }
}

@media screen and (max-width: 1279px) {
  .template {
    background-color: var(--color-gray-50);
    min-height: 100vh;

    .main {
      min-height: calc(100vh - 106px - 96px);

      :global(.wrapper) {
        padding-top: 48px;
        padding-bottom: 16px;
      }

      .content {
        .part-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          &__main {
            width: 100%;
            max-width: 864px;
            margin-right: initial;

            &__outlet {
              width: 100%;
              margin-top: 32px;
            }
          }

          &__side {
            max-width: 100%;
            position: relative;

            .info {
              width: 100%;
              margin-top: 24px;

              &__title {
                color: var(--color-main);
              }

              &__text {
                margin-top: 16px;
              }

              &__button {
                margin-top: 24px;

                & > * {
                  width: 100%;
                }
              }
            }

            .fuel-info {
              margin-top: 48px;

              &__text {
                margin-bottom: 8px;
              }

              &__number {
                margin-bottom: -8px;
              }

              &__icon {
                fill: rgba(255, 255, 255, 0.25);
                width: 58px;
                position: absolute;
                top: 32px;
                right: 24px;
              }
            }

            .adaptive-skip-button {
              width: 100%;
              margin-top: 48px;
            }
          }
        }

        .sec-title {
          color: var(--color-main);
          margin-bottom: 8px;
        }

        .sec-title-2 {
          margin-top: 16px;
        }
      }
    }

    &--edit-role {
      :global(.wrapper) {
        padding: initial !important;
      }

      .part-list__main__outlet {
        margin-top: initial !important;
      }

      .part-list__main {
        max-width: 100% !important;
        margin-right: initial !important;
      }
    }
  }

  .rf-pop-up {
    &__icon-container {
      top: 24px;
      left: 24px;
    }
  }
}

@media screen and (max-width: 767px) {
  .rf-pop-up {
    &__icon-container {
      top: 12px;
      left: 12px;
    }
  }
}
