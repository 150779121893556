.label {
  background-color: var(--color-main);
  color: var(--color-white);
  display: inline-block;
  padding: 5px 8px 3px 8px;

  &--white {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-300);
    border-radius: 2px;

    .text {
      color: var(--color-black);
      font-weight: 500;
    }
  }

  &--main-rounded {
    border: 1px solid var(--color-gray-300);
    border-radius: 1000px;
  }

  &--small {
    border-radius: 2px;
    padding: 2px 4px 1px 4px;

    .text {
      font-size: 10px;
      font-weight: 500;
    }
  }
}
