.role {
  &__back-link {
    margin-bottom: 32px;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info {
    margin-top: 32px;

    &__value-group {
      &:not(:nth-child(1)) {
        margin-top: 32px;
      }

      &--hide {
        display: none;
      }
    }

    &__title {
      margin-bottom: 16px;
    }

    &__label-list {
      margin-top: -8px;
      display: flex;
      flex-wrap: wrap;

      & > *:not(:nth-last-child(1)) {
        margin-right: 8px;
      }

      & > * {
        margin-top: 8px;
      }
    }

    .info__value-group__list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .info__value-group {
        background: var(--color-gray-50);
        border: 1px solid var(--color-gray-300);
        border-radius: var(--border-radius-2);
        flex: 1 1 calc(33.33% - 16px);
        padding: 16px;
        margin-top: initial;

        &:nth-last-child(1) {
          // flex-grow: 55;
        }
      }
    }
  }

  .remove-role-link {
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .role {
    .info {
      .info__value-group__list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .info__value-group {
          flex: initial;
          width: 100%;

          &:nth-last-child(1) {
            flex-grow: initial;
          }
        }
      }
    }
  }
}
