.section {
  :global(.background) {
    background-color: var(--color-gray-50);
  }

  :global(.wrapper) {
    :global(.content) {
      padding-top: 3vh;
      padding-bottom: calc(1vh + 6.65vw);
    }
  }

  .block-list {
    display: flex;
    align-items: center;
    position: relative;

    &__item {
      position: relative;

      &:nth-child(1) {
        flex: 1 0 auto;
        max-width: 752px;
        margin-right: 32px;
      }
    }
  }

  .sec-title {
    margin-bottom: 8px;
  }

  .breadcrumbs {
    margin-bottom: 24px;
  }

  .text {
    margin-top: 24px;
  }

  .logo {
    margin-top: 24px;
  }

  .image {
    border-radius: 16px;
    box-shadow: 0px 3.3483803272247314px 2.2138051986694336px 0px rgba(175, 182, 202, 0.02),
      0px 8.046623229980469px 5.32008171081543px 0px rgba(175, 182, 202, 0.03),
      0px 15.151078224182129px 10.017241477966309px 0px rgba(175, 182, 202, 0.04),
      0px 27.026933670043945px 17.869047164916992px 0px rgba(175, 182, 202, 0.04),
      0px 50.550907135009766px 33.422088623046875px 0px rgba(175, 182, 202, 0.05),
      0px 121px 80px 0px rgba(175, 182, 202, 0.07);
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      height: 376px;
    }
  }
}

.section + :global(.section-divider) {
  margin-top: -6.65vw;
}

@media screen and (max-width: 1279px) {
  .section {
    :global(.background) {
      background-color: var(--color-gray-50);
    }

    :global(.wrapper) {
      padding-top: var(--header-height);
      padding-bottom: initial;
      margin-top: calc(0px - var(--header-height));

      :global(.content) {
        padding-top: 64px;
        padding-bottom: 64px;
      }
    }

    .block-list {
      flex-wrap: wrap;

      &__item {
        position: relative;

        &:nth-child(1) {
          width: 100%;
          max-width: 752px;
          margin-right: initial;
        }

        &:nth-child(2) {
          width: 100%;
          margin-top: 32px;
        }
      }
    }

    .image {
      border-radius: 16px;
      box-shadow: 0px 3.3483803272247314px 2.2138051986694336px 0px rgba(175, 182, 202, 0.02),
        0px 8.046623229980469px 5.32008171081543px 0px rgba(175, 182, 202, 0.03),
        0px 15.151078224182129px 10.017241477966309px 0px rgba(175, 182, 202, 0.04),
        0px 27.026933670043945px 17.869047164916992px 0px rgba(175, 182, 202, 0.04),
        0px 50.550907135009766px 33.422088623046875px 0px rgba(175, 182, 202, 0.05),
        0px 121px 80px 0px rgba(175, 182, 202, 0.07);
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: initial;
      }
    }
  }
}
