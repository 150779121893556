.container {
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-top: 96px;
  padding-bottom: 80px;
  padding-left: 50px;

  .content {
    max-width: 606px;
    margin-right: auto;

    .header-button {
      position: absolute;
      top: 48px;
      right: 64px;
    }

    .illustration {
      position: relative;
      top: -9px;
      left: -51px;

      :global(.state-active) {
        opacity: 0;
        transition: var(--transition-fast);
      }

      :global(.state-active-lamp-color-1) {
        fill: #6b7e9a;
        transition: var(--transition-fast);
      }

      :global(.state-active-lamp-color-2) {
        fill: #8aa1bc;
        transition: var(--transition-fast);
      }

      &.illustration--active {
        :global(.state-active) {
          opacity: 1;
        }

        :global(.state-active-lamp-color-1) {
          fill: rgb(234, 107, 61);
        }

        :global(.state-active-lamp-color-2) {
          fill: rgb(233, 189, 104);
        }
      }
    }

    .slider-container {
      text-align: center;
      color: #ffffff;
      max-width: 478px;
      margin-top: 48px;
      margin-right: auto;
      margin-left: auto;

      &__slider {
        margin-top: 24px;

        :global(.slick-dots) {
          li {
            width: initial;
            height: initial;
            margin: initial;
            margin-right: 4px;
            margin-left: 4px;

            button {
              background-color: var(--color-white-25);
              border-radius: 2px;
              width: 8px;
              height: 8px;
              padding: initial;
              transition: var(--transition-fast);

              &:before {
                display: none;
              }
            }
          }

          :global(li.slick-active) {
            button {
              background-color: var(--color-white);
              width: 32px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .container {
    height: initial;
    min-height: initial;
    display: flex;
    align-items: center;
    padding-top: 64px;
    padding-right: 16px;
    padding-bottom: 64px;
    padding-left: 16px;

    .content {
      max-width: 100%;
      margin-left: auto;

      .header-button {
        position: absolute;
        top: 48px;
        right: 64px;
      }

      .illustration {
        left: initial;
        top: initial;

        :global(.state-active) {
          opacity: 0;
          transition: var(--transition-fast);
        }

        :global(.state-active-lamp-color-1) {
          fill: #6b7e9a;
          transition: var(--transition-fast);
        }

        :global(.state-active-lamp-color-2) {
          fill: #8aa1bc;
          transition: var(--transition-fast);
        }

        &.illustration--active {
          :global(.state-active) {
            opacity: 1;
          }

          :global(.state-active-lamp-color-1) {
            fill: rgb(234, 107, 61);
          }

          :global(.state-active-lamp-color-2) {
            fill: rgb(233, 189, 104);
          }
        }
      }

      .slider-container {
        text-align: center;
        color: #ffffff;
        max-width: 478px;
        margin-top: 48px;
        margin-right: auto;
        margin-left: auto;

        &__slider {
          margin-top: 24px;

          :global(.slick-dots) {
            li {
              width: initial;
              height: initial;
              margin: initial;
              margin-right: 4px;
              margin-left: 4px;

              button {
                background-color: var(--color-white-25);
                border-radius: 2px;
                width: 8px;
                height: 8px;
                padding: initial;
                transition: var(--transition-fast);

                &:before {
                  display: none;
                }
              }
            }

            :global(li.slick-active) {
              button {
                background-color: var(--color-white);
                width: 32px;
              }
            }
          }
        }
      }
    }
  }
}
