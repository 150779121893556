.logged-block {
  text-align: center;

  &__button {
  }

  &__link {
    margin-top: 32px;
  }
}
