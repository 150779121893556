.subscription-card {
  background: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
  transition: var(--transition-fast);

  &:hover {
    border-color: var(--color-main);
    box-shadow: var(--shadow-card-hover);
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__top-part {
      width: 100%;
    }

    &__bottom-part {
      width: 100%;
      position: relative;
    }
  }

  .title {
    width: 102.5%;
    position: relative;

    &:before {
      content: '';
      background-color: var(--color-main);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 5px;
      height: 30px;
      position: absolute;
      top: -4px;
      left: -25px;
    }
  }

  .price {
    color: var(--color-gray-800);
    display: flex;
    align-items: center;
    margin-top: 16px;
    position: relative;

    &__dot {
      background-color: var(--color-gray-800);
      border-radius: 50%;
      width: 4px;
      height: 4px;
      margin-right: 12px;
      margin-left: 12px;
      position: relative;
      top: -2px;
    }
  }

  .button {
    width: 100%;
    margin-top: 24px;
  }

  .discount {
    position: absolute;
    top: -10px;
    right: 24px;
  }

  .suggested {
    color: var(--color-main);
    background-color: var(--color-gray-50);
    border-bottom: 1px solid var(--color-gray-300);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: calc(100% + 24px + 24px);
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 24px;
    margin-top: -24px;
    margin-bottom: 16px;
    position: relative;
    left: -24px;
  }
}