.role-selector {
  display: flex;
  align-items: center;
  position: relative;

  cursor: pointer;
}

.role-selector__nav-selected {
  display: flex;
  align-items: center;
}

.role-selector__icon {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  transition: var(--transition-fast);

  svg path {
    transition: var(--transition-fast);
  }
}

.role-selector:hover .role-selector__icon,
.role-selector:global(.active) .role-selector__icon {
  background-color: var(--color-main);
}

.role-selector__nav-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: var(--color-white);

  overflow: hidden;

  &__span {
    font-weight: 400;
    text-transform: initial;
    opacity: 0.5;
    margin-right: 8px;
  }
}

.role-selector__nav-icon {
  margin-left: 11px;

  transition: var(--transition-fast);
}

.role-selector:global(.active) .role-selector__nav-icon {
  transform: rotateX(180deg);
}

.role-selector__nav-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 8px + 8px);
  margin-top: 32px;
  position: relative;
  left: -8px;
}

.role-selector__nav-list-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--color-gray-800);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: calc(50% - 8px - 8px);
  margin-right: 8px;
  margin-left: 8px;
  padding: 16px 16px;
  position: relative;
  cursor: pointer;
  transition: var(--transition-fast);

  &:nth-child(1n + 3) {
    margin-top: 16px;
  }

  &:after {
    content: '';
    opacity: 0;
    border: 2px solid var(--color-main);
    border-radius: 8px;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    transition: var(--transition-fast);
  }

  &__text {
  }

  &__circle {
    background: var(--color-white);
    border: 2px solid var(--color-gray-300);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    transition: var(--transition-fast);

    &__icon {
      width: 14px;
      position: relative;
      top: 1px;
    }
  }

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
    color: var(--color-main);

    &:after {
      opacity: 1;
    }
  }

  &:global(.active) {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
    pointer-events: none;

    &:after {
      opacity: 1;
    }

    .role-selector__nav-list-item__text {
      color: var(--color-main);
    }

    .role-selector__nav-list-item__circle {
      background-color: var(--color-main);
      border-color: var(--color-main);
    }
  }

  &--pressed {
    background-color: var(--color-main);

    .role-selector__nav-list-item__text {
      color: var(--Neutral-0) !important;
    }
  }
}

.role-selector.role-selector--color-white .role-selector__icon {
  background-color: rgba(144, 157, 173, 0.15);
}

.role-selector.role-selector--color-white:hover .role-selector__icon,
.role-selector.role-selector--color-white:global(.active) .role-selector__icon {
  background-color: var(--color-main);
}

.role-selector.role-selector--color-white .role-selector__icon svg path {
  fill: var(--color-black);
}

.role-selector.role-selector--color-white:hover .role-selector__icon svg path,
.role-selector.role-selector--color-white:global(.active) .role-selector__icon svg path {
  fill: var(--color-white);
}

.role-selector.role-selector--color-white .role-selector__nav-text {
  color: var(--color-black);
}

.role-selector.role-selector--color-white .role-selector__nav-icon svg path {
  fill: var(--color-black);
}

@media screen and (max-width: 767px) {
  .role-selector__nav-list {
    width: 100%;
    margin-top: 8px;
    left: initial;
  }

  .role-selector__nav-list-item {
    width: 100%;
    margin-right: initial;
    margin-left: initial;

    &:nth-child(1n + 3) {
      margin-top: initial;
    }

    &:not(:nth-child(1)) {
      margin-top: 8px;
    }
  }
}
