.section {
  color: var(--color-white);
  overflow: hidden;

  :global(.background) {
    background-color: var(--color-space-blue-1);
  }

  :global(.content) {
    padding-bottom: 64px;
  }

  .bg-elements > * {
    display: block;
    width: 100%;
  }

  .block-list {
    display: flex;
    position: relative;

    &__item-1 {
      width: 50%;
    }

    &__item-2 {
      width: 50%;
    }
  }

  .text {
    margin-top: 24px;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-top: 64px;
    position: relative;
    left: -8px;

    &__item {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .image {
    width: 664px;
    position: relative;
    top: -40px;
    left: 20px;
    pointer-events: none;

    svg {
      width: 1076px;
      max-width: initial;
      position: absolute;
      top: -129px;
      left: -220px;
    }
  }

  &--var-1 {
    .bg-elements {
      display: none;
    }

    .block-list {
      &__item-1 {
        text-align: center;
        width: 100%;
      }

      &__item-2 {
        display: none;
      }
    }

    .button-group {
      justify-content: center;

      &__item:not(:nth-child(1)) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .section {
    :global(.content) {
      padding-bottom: initial;
    }

    .block-list {
      flex-wrap: wrap;

      &__item-1 {
        width: 100%;
      }

      &__item-2 {
        width: 100%;
        margin-top: 40px;
      }
    }

    .image {
      width: 100%;
      top: initial;
      left: initial;

      svg {
        width: 100%;
        max-width: 100%;
        position: relative;
        top: initial;
        left: initial;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section {
    .button-group {
      &__item {
        width: 100%;
        margin-right: initial;
        margin-left: initial;

        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        > * {
          width: 100%;
        }
      }
    }

    .image {
      margin-top: -64px;
      width: 100%;
      top: initial;
      left: initial;

      svg {
        width: 200%;
        max-width: 200%;
        position: relative;
        top: initial;
        left: -50%;
      }
    }
  }
}
