.content {
  > *:nth-child(1) {
    margin-top: initial;
  }

  > *:nth-last-child(1) {
    margin-bottom: initial;
  }

  > * {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5 {
    margin-top: 64px;
    margin-bottom: 24px;
  }

  > p {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  > img {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  > h1 {
    font-size: 52px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  > h2 {
    font-size: 46px;
    font-weight: 800;
    line-height: 125%;
    letter-spacing: 0.02em;
  }

  > h3 {
    font-size: 32px;
    font-weight: 800;
    line-height: 125%;
    letter-spacing: 0.02em;
  }

  > h4 {
    font-size: 24px;
    font-weight: 800;
    line-height: 125%;
    letter-spacing: 0.02em;
  }

  > h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  > p {
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
  }

  a {
    text-decoration: underline;
    color: var(--color-main);
    font-weight: 500;
  }

  a:after {
    content: '';
    background-color: initial;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  strong,
  b {
    color: var(--color-black);
    font-weight: 800;
  }

  > ul {
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    padding-left: 24px;
    margin-top: 24px;
    margin-bottom: 40px;
    position: relative;
  }

  > ul > li {
    padding-left: 20px;
    position: relative;
  }

  > ul > li:not(:nth-child(1)) {
    margin-top: 14px;
  }

  > ul > li:before {
    content: '';
    background-color: var(--color-black);
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 10px;
    left: 4px;
  }
}

@media screen and (max-width: 767px) {
  .content {
    > h1 {
      font-size: 32px;
    }

    > h2 {
      font-size: 32px;
    }

    > h3 {
      font-size: 32px;
    }

    > h4 {
      font-size: 24px;
    }

    > h5 {
      font-size: 20px;
    }
  }
}
