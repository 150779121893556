@import '../shared/inputShared.module.scss';

.main-container {
  position: relative;

  .input {
    &__container {
      input {
        padding-left: 48px;
      }
    }
  }

  .autocomplete-dropdown-container {
    border-radius: 4px;
    border: 1px solid var(--color-gray-200);
    background: #ffffff;
    box-shadow: 0px 121px 80px 0px rgba(175, 182, 202, 0.07), 0px 50.551px 33.422px 0px rgba(175, 182, 202, 0.05),
      0px 27.027px 17.869px 0px rgba(175, 182, 202, 0.04), 0px 15.151px 10.017px 0px rgba(175, 182, 202, 0.04),
      0px 8.047px 5.32px 0px rgba(175, 182, 202, 0.03), 0px 3.348px 2.214px 0px rgba(175, 182, 202, 0.02);
    width: 100%;
    position: absolute;
    top: calc(100% + 4px);
    left: 0px;
    z-index: 55;

    .suggestion-item {
      color: var(--color-gray-800);
      background: #ffffff;
      padding-top: 5px;
      padding-right: 16px;
      padding-bottom: 3px;
      padding-left: 16px;
      cursor: pointer;
      transition: var(--transition-fast);

      &:hover {
        background-color: var(--color-gray-50);
      }

      &--active {
        background-color: var(--color-gray-200);
      }
    }
  }
}
