.role {
  &__back-link {
    margin-bottom: 32px;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main {
    margin-top: 32px;
  }

  .footer {
    margin-top: 32px;

    &__button-group {
      display: flex;
      justify-content: space-between;

      > * {
        min-width: 170px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
}
