.card {
  &__title {
    color: var(--color-gray-700);
    margin-bottom: 8px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &__part_1 {
      display: flex;
      flex: 1 0 auto;
      margin-right: 24px;
    }

    &__part_2 {
      display: flex;
    }
  }

  &__element-2 {
    width: 140px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 1279px) {
  .card {
    &__title {
      color: var(--color-gray-700);
      margin-bottom: 8px;
    }
  
    &__element-2 {
      width: 88px;
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .card {
    &__footer {
      flex-wrap: wrap;

      &__part_1 {
        width: 100%;
        flex-wrap: wrap;
        margin-right: initial
      }
  
      &__part_2 {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  
    &__element-2 {
      width: 100%;
      margin-right: initial;
      margin-top: 24px;
    }

    &__element-3 {
      margin-top: 24px;
    }
  }
}