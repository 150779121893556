.dashboard {
  .call-to-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .where-you-left-off {
    &__title {
      margin-bottom: 24px;
    }
  }

  .share {
    margin-top: 64px;

    &__text {
      margin-top: 16px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      &__button-list {
        > *:not(:nth-last-child(1)) {
          margin-right: 8px;
        }
      }
    }

    &__bg-el {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }

  .recommended {
    margin-top: 64px;

    &__title {
      margin-bottom: 24px;
    }

    &__list > *:not(:nth-child(1)) {
      margin-top: 16px;
    }
  }

  .refer {
    margin-top: 64px;

    &__text {
      margin-top: 16px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
    }

    &__bg-el {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}

.refer-pop-up {
  &__input-list {
    margin-top: 24px;
  }

  &__footer-button-list {
    display: flex;
    justify-content: flex-end;

    &__item:not(:nth-last-child(1)) {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .dashboard {
    .call-to-action {
      > * {
        width: 100%;
      }
    }

    .share {
      &__footer {
        flex-wrap: wrap;
        justify-content: initial;
        align-items: initial;
        margin-top: 24px;

        &__button-list {
          margin-top: 24px;

          > *:not(:nth-last-child(1)) {
            margin-right: 8px;
            margin-bottom: 8px;
          }
        }
      }

      &__bg-el {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }

    .recommended {
      margin-top: 64px;

      &__title {
        margin-bottom: 24px;
      }

      &__list > *:not(:nth-child(1)) {
        margin-top: 16px;
      }
    }

    .refer {
      margin-top: 64px;

      &__text {
        margin-top: 16px;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      }

      &__bg-el {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
}
