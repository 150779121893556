.section {
  position: relative;
  z-index: 88;

  :global(.background) {
    background-color: #030620;
  }

  :global(.wrapper) {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .block-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__item-1 {
      display: flex;
      align-items: center;
    }
  }

  .text-1 {
    color: var(--color-cyan);
  }

  .text-2 {
    color: var(--color-white);
    margin-left: 16px;
  }
}

@media screen and (max-width: 1279px) {
  .section {
    .text-2 {
      display: none;
    }
  }
}
