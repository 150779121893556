.container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 64px;
  padding-right: 142px;
  padding-bottom: 64px;

  .container-top-part {
    height: 19px;
    padding-left: 64px;
  }

  .container-bottom-part {
    height: 19px;
  }

  .content {
    width: 416px;
    max-width: 100%;
    margin-left: auto;
    position: relative;

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      color: var(--color-white);
      margin-top: 40px;
    }

    .text {
      color: var(--color-white);
      margin-top: 24px;

      &__1 {
        color: rgba(var(--Neutral-0-rgb), 0.5);
      }
    }

    .button {
      margin-top: 40px;

      > * {
        width: 100%;
      }
    }

    .code-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      &__line {
        background-color: var(--color-gray-700);
        border-radius: 2px;
        width: 28px;
        height: 2px;
      }

      &__input-group {
        display: flex;

        &__input {
          font-family: 'Axiforma', sans-serif;
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0.02em;
          text-align: center;
          color: var(--color-white);
          background-color: rgba(255, 255, 255, 0.1);
          border: 2px solid var(--color-gray-700);
          outline: none;
          width: 54px;
          height: 80px;
          position: relative;
          transition: var(--transition-fast);

          &:focus {
            border-color: var(--color-white);
            z-index: 55;
          }

          &:not(:nth-child(1)) {
            margin-left: -2px;
          }

          &:nth-child(1) {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:nth-last-child(1) {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }

    .resend {
      color: var(--color-white);
      display: flex;
      align-items: center;
      margin-top: 40px;

      &__link {
        margin-left: 10px;

        &--disabled {
          opacity: 0.9;
          filter: grayscale(100%);
          pointer-events: none;
        }
      }
    }

    .resend-timer {
      color: var(--color-white);
    }
  }
}

@media screen and (max-width: 1279px) {
  .container {
    height: initial;
    min-height: initial;
    align-items: initial;
    justify-content: center;
    padding-top: initial;
    padding-right: initial;
    padding-bottom: initial;

    .container-top-part {
      width: 416px;
      max-width: 100%;
      padding-left: initial;
      margin-right: auto;
      margin-bottom: 64px;
      margin-left: auto;
    }

    .content {
      margin-right: auto;
    }

    .astronomic-logo {
      width: 124px;
    }
  }
}

@media screen and (max-width: 767px) {
  .astronomic-logo {
    width: 124px;
  }
}
