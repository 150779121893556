.countdown {
  color: var(--color-white);
  background-color: var(--color-main);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  padding: 16px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__text {
    margin-right: 8px;
    position: relative;
    bottom: -1px;
  }

  &--color-gray {
    border: 1px solid var(--color-gray-300);
    background-color: var(--color-gray-200);

    .countdown__icon {
      svg {
        fill: var(--color-main) !important;
      }
    }

    .countdown__text {
      color: var(--color-gray-800);
    }

    .countdown__timer {
      color: var(--color-main);
    }
  }
}