.add-info {
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid var(--color-white-25);
  background: var(--color-space-blue-3);
  overflow: hidden;

  &__part-1 {
    min-width: calc(120px + 16px * 2);
    padding: 16px;
    padding-right: 24px;
    position: relative;
    cursor: pointer;
    transition: var(--transition-fast);

    &:after {
      content: '';
      background-color: var(--color-gray-50);
      width: 1px;
      height: calc(100% - 16px - 16px);
      position: absolute;
      top: 16px;
      right: 0px;
    }

    &:hover {
      background-color: var(--color-main);

      .add-info__identifier {
        color: var(--color-white);
      }
    }
  }

  &__part-2 {
    min-width: calc(120px + 16px * 2);
    padding: 16px;
    padding-left: 24px;
    position: relative;
    cursor: pointer;
    transition: var(--transition-fast);

    &:hover {
      background-color: var(--color-main);

      .add-info__identifier {
        color: var(--color-white);
      }
    }
  }

  &__value {
    color: var(--color-white);
  }

  &__identifier {
    color: var(--color-gray-600);
    margin-top: 8px;
    transition: var(--transition-fast);
  }
}
