.widget {
  .parts {
    display: flex;
    gap: 32px;

    &__item {
      width: calc(50% - 16px);
    }
  }

  .tile {
    overflow: hidden;
  }

  .table {
    .row {
      background: var(--Background-Neutral-Container, #fff);
      display: flex;
      justify-content: space-between;

      &:not(:nth-child(1)) {
        border-top: 1px solid var(--Border-Neutral-Default, #dadbe2);
      }

      &--header {
        background: var(--Background-Neutral-Primary, #f1f1f4);
        align-items: center;

        p {
          font-weight: 700;
        }
      }

      .col {
        width: 25%;
        padding: 10px 12px;
      }
    }
  }

  .form {
    &__text-2 {
      margin-top: 0px;
    }

    &__text-4 {
      color: var(--color-main);
      margin-top: 8px;
    }

    &__text-5 {
      margin-top: 8px;
    }

    &__text-6 {
      margin-top: 16px;
    }

    &__input {
      margin-top: 16px;
    }

    &__body {
      padding: 32px;
    }

    .steps {
      &__item {
        display: flex;
        position: relative;

        &:not(:nth-child(1)) {
          margin-top: 32px;
        }

        &:nth-last-child(1) {
          .steps__item__line {
            display: none;
          }
        }

        &__dot {
          background-color: var(--color-main);
          border-radius: 50%;
          width: 12px;
          height: 12px;
        }

        &__line {
          background-color: var(--Border-Neutral-Default, #dadbe2);
          width: 2px;
          height: calc(100% + 4px);
          position: absolute;
          top: calc(12px + 8px);
          left: 5px;
        }

        &__part-left {
          width: calc(12px + 24px);
        }

        &__part-right {
          width: calc(100% - 12px + 24px);
        }
      }
    }

    &__footer {
      border: 1px solid var(--Border-Neutral-Default, #dadbe2);
      background: var(--Background-Neutral-Primary, #f1f1f4);
      padding: 16px 0px;
      position: relative;

      &__icon-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 24px;
        position: absolute;
        top: -16px;
        left: 0px;
      }

      &__parts {
        display: flex;

        &__item {
          text-align: center;
          width: 50%;
          padding-right: 8px;
          padding-left: 8px;
          position: relative;

          &:nth-child(1):after {
            content: '';
            background-color: var(--Border-Neutral-Default, #dadbe2);
            display: block;
            width: 2px;
            height: 48px;
            margin: auto 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
          }
        }
      }

      &__text {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;

        &__price {
          color: var(--color-main);
          margin-right: 8px;
          margin-left: 8px;
        }
      }
    }

    .spend-amount-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: -8px;
    }
  }

  &--layout-var-a {
    .parts {
      &__item {
        &:nth-child(1) {
          width: calc(45% - 16px);
        }

        &:nth-child(2) {
          width: calc(55% - 16px);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .widget {
    .parts {
      flex-wrap: wrap;
      // gap: 32px;

      &__item {
        width: 100%;

        &:nth-child(2) {
          order: -1;
        }
      }
    }

    .form {
      &__body {
        padding: 32px 16px;
      }

      .steps {
        &__item {
          &__part-left {
            display: none;
          }

          &__part-right {
            width: 100%;
          }
        }
      }
    }

    &--layout-var-a {
      .parts {
        &__item {
          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%;
          }
        }
      }
    }
  }
}
