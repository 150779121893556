.container {
  border-radius: var(--Radius-sm, 8px);
  border: 1px solid var(--Border-Neutral-Default, #dadbe2);
  background: var(--Background-Neutral-Container, #fff);
  box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.08);
  height: 48px;
  padding: 4px;

  input {
    display: none;
  }

  .options {
    display: flex;
    height: 100%;

    &__item {
      flex: 1 1 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      user-select: none;

      &__bg {
        border-radius: var(--Radius-xs, 4px);
        background: var(--brand-orange-600, #e24e29);
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        pointer-events: none;
        transition: var(--transition-fast);
      }

      &__label {
        color: var(--Text-onLight-Neutral-Primary, #202145);
        text-align: center;
        font-size: var(--Label-sm-font-size, 14px);
        font-weight: 400;
        line-height: var(--Label-sm-line-height, 100%);
        letter-spacing: var(--Text-sm-letter-spacing, 0px);
        position: relative;
        transition: var(--transition-fast);
      }

      input:checked + .options__item__bg {
        opacity: 1;
      }

      input:checked + * + .options__item__label {
        color: var(--Neutral-0);
      }
    }
  }
}
