.toast {
  &__title {
    color: var(--color-black);
    padding-right: 50px;
  }

  &__description {
    color: var(--color-black);
    margin-top: 8px;

    b, strong {
      font-weight: 700;
      color: var(--color-black);
    }
  }

  &__button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: initial;
    margin-top: 16px;
    cursor: pointer;

    &__text {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      color: var(--color-info-600);
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;

      > * {
        fill: var(--color-info-600);
        width: 11px;
      }
    }

    &__text + &__icon {
      margin-left: 8px;;
    }
  }

  &--var-error {
    &:before {
      background-color: var(--color-red-200);
    }

    .toast__icon > * {
      fill: var(--color-red-400);
    }

    .toast__button {
      &__text {
        color: var(--color-red-400);
      }

      &__icon > * {
        fill: var(--color-red-400);
      }
    }
  }

  &--var-warning {
    &:before {
      background-color: var(--color-caution-200);
    }

    .toast__icon > * {
      fill: var(--color-caution-400);
    }

    .toast__button {
      &__text {
        color: var(--color-caution-600);
      }

      &__icon > * {
        fill: var(--color-caution-600);
      }
    }
  }

  &--var-success {
    &:before {
      background-color: var(--color-green-200);
    }

    .toast__icon > * {
      fill: var(--color-green-400);
    }

    .toast__button {
      &__text {
        color: var(--color-green-600);
      }

      &__icon > * {
        fill: var(--color-green-600);
      }
    }
  }

  &--var-info {
    &:before {
      background-color: var(--color-info-200);
    }

    .toast__icon > * {
      fill: var(--color-info-400);
    }

    .toast__button {
      &__text {
        color: var(--color-info-600);
      }

      &__icon > * {
        fill: var(--color-info-600);
      }
    }
  }
}