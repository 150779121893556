.section-divider {
  width: 100%;
  // height: 96px;
  // margin-bottom: -1px;
  position: relative;
  overflow: hidden;
  margin-bottom: -1px;

  svg {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    // transform: scaleX(1.8);
  }

  &--color-white {
    svg {
      path {
        fill: var(--color-white);
        stroke: var(--color-gray-300);
      }
    }
  }

  &--color-gray {
    svg {
      path {
        fill: var(--color-gray-50);
        stroke: var(--color-gray-300);
      }
    }
  }

  &--color-blue {
    svg {
      path {
        fill: var(--color-space-blue-1);
        // stroke: var(--color-gray-300);
        stroke: initial;
      }
    }
  }

  &--color-blue-dark {
    svg {
      path {
        fill: var(--color-space-dark);
        stroke: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &--color-neutral-primary {
    svg {
      path {
        fill: var(--Background-Neutral-Primary);
        stroke: var(--Neutral-200);
      }
    }
  }

  &--color-neutral-secondary {
    svg {
      path {
        fill: var(--Background-Neutral-Secondary);
        stroke: var(--Neutral-200);
      }
    }
  }
}
