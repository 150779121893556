.footer {
  color: var(--color-white);
  position: relative;
  overflow: hidden;

  :global(.background) {
    background-color: var(--color-space-blue-1);
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }

  :global(.wrapper) {
    padding-bottom: initial;
  }

  .header-part {
    &__grid {
      display: flex;
      justify-content: space-between;
      width: calc(100% + 16px + 16px);
      padding-bottom: var(--wrap-pad-2);
      position: relative;
      left: -16px;

      &__col {
        margin-right: 16px;
        margin-left: 16px;
      }

      &__col-2 {
        max-width: 416px;
      }

      &__col-3 {
        max-width: 304px;
        visibility: hidden;
      }

      &__col-4 {
        max-width: 304px;
      }
    }
  }

  .logo {
    width: 192px;

    & > * {
      width: 100%;
    }
  }

  .socials-group {
    display: flex;
    align-items: center;
    margin-top: 24px;

    &__item {
      svg {
        transition: var(--transition-fast);
      }

      &:hover {
        svg {
          fill: var(--color-white);
          fill-opacity: 1;
        }
      }
    }

    &__item:not(:nth-last-child(1)) {
      margin-right: 20px;
    }
  }

  .title {
    margin-bottom: 16px;
  }

  .link-list {
    ul {
      li {
        &:not(:nth-child(1)) {
          margin-top: 8px;
        }

        a {
          color: var(--color-white);
          transition: var(--transition-fast);

          &:hover {
            color: var(--color-main);
          }

          &:global(.active) {
            color: var(--color-main);
            pointer-events: none;
          }
        }
      }
    }
  }

  .footer-part {
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding: 32px 0px;
    position: relative;

    &__grid {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__col {
        width: 312px;
      }

      &__col-3 {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .link-list-2 {
    width: 100%;
    ul {
      display: flex;
      justify-content: flex-end;
      width: calc(100% + 20px + 20px);
      position: relative;
      left: -20px;

      li {
        padding-right: 20px;
        padding-left: 20px;
        position: relative;

        &:not(:nth-last-child(1)):after {
          content: '';
          background-color: var(--color-white);
          border-radius: 50%;
          width: 4px;
          height: 4px;
          margin: auto 0px;
          position: absolute;
          top: 0px;
          right: -2px;
          bottom: 0px;
        }

        a {
          color: var(--color-white);
          transition: var(--transition-fast);

          &:hover {
            color: var(--color-main);
          }

          &:global(.active) {
            color: var(--color-main);
            pointer-events: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .footer {
    overflow: hidden;

    .header-part {
      &__grid {
        flex-wrap: wrap;
        width: 100%;
        left: initial;

        &__col {
          margin-right: initial;
          margin-left: initial;
        }

        &__col-2 {
          max-width: 100%;
          margin-top: 40px;
        }

        &__col-3 {
          display: none;
          max-width: 100%;
          margin-top: 40px;
        }

        &__col-4 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
    }

    .footer-part {
      &__grid {
        flex-wrap: wrap;

        &__col {
          width: 100%;
        }

        &__col-3 {
          justify-content: center;
          margin-top: 16px;
        }
      }
    }

    .link-list-2 {
      ul {
        justify-content: center;
        width: 100%;
        left: initial;
      }
    }
  }
}
