:root {
  --toastify-toast-width: 420px;
}

.Toastify__toast {
  padding: 16px 16px 16px 24px;
  box-shadow: 0px 121px 80px 0px #AFB6CA12;

  &:before {
    content: '';
    background-color: var(--color-black);
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000;
  }
}

.Toastify__toast-body {
  font-family: 'Axiforma', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  align-items: flex-start;

  b, strong {
    font-weight: 700;
    color: var(--color-black);
  }
}

.Toastify__toast-icon {
  width: 22px;
  min-width: 22px;
  height: 22px;
  margin-right: 16px;

  > * {
    display: block;
    width: 100%;
  }
}

.Toastify__toast--default {
  &:before {
    background-color: var(--color-info-200);
  }
}
.Toastify__toast--info {
  &:before {
    background-color: var(--color-info-200);
  }

  .Toastify__toast-icon > * {
    fill: var(--color-info-400);
  }
}
.Toastify__toast--success {
  &:before {
    background-color: var(--color-green-200);
  }

  .Toastify__toast-icon > * {
    fill: var(--color-green-400);
  }
}
.Toastify__toast--warning {
  &:before {
    background-color: var(--color-caution-200);
  }

  .Toastify__toast-icon > * {
    fill: var(--color-caution-400);
  }
}
.Toastify__toast--error {
  &:before {
    background-color: var(--color-red-200);
  }

  .Toastify__toast-icon > * {
    fill: var(--color-red-400);
  }
}

.Toastify__progress-bar {
  opacity: initial;
  height: 4px;
}

.Toastify__progress-bar--default {
  background: var(--color-info-200);
}

.Toastify__progress-bar--info {
  background-color: var(--color-info-200);
}

.Toastify__progress-bar--success {
  background-color: var(--color-green-200);
}

.Toastify__progress-bar--warning {
  background-color: var(--color-caution-200);
}

.Toastify__progress-bar--error {
  background-color: var(--color-red-200);
}