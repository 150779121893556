.toast {
  background-color: var(--color-white);
  border-radius: var(--border-radius-2);
  box-shadow: 0px 121px 80px 0px #AFB6CA12;
  display: flex;
  padding: 16px 16px 16px 24px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &__left {
    width: 22px;
    margin-right: 16px;
  }

  &__right {
    color: initial;
  }

  &__close {
    background: none;
    border: none;
    outline: none;
    padding: initial;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    > * {
      fill: var(--color-black);
      width: 14px;
    }
  }

  &__title {
    padding-right: 50px;
  }

  &__description {
    margin-top: 8px;

    b, strong {
      font-weight: 700;
      color: var(--color-black);
    }
  }

  &__button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: initial;
    margin-top: 16px;
    cursor: pointer;

    &__text {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;

      > & {
        width: 11px;
      }
    }

    &__text + &__icon {
      margin-left: 8px;;
    }
  }

  &__icon {
    width: 22px;

    > * {
      display: block;
      width: 100%;
    }
  }

  &--var-error {
    &:before {
      background-color: var(--color-red-200);
    }

    .toast__icon > * {
      fill: var(--color-red-400);
    }

    .toast__button {
      &__text {
        color: var(--color-red-400);
      }

      &__icon > * {
        fill: var(--color-red-400);
      }
    }
  }

  &--var-warning {
    &:before {
      background-color: var(--color-caution-200);
    }

    .toast__icon > * {
      fill: var(--color-caution-400);
    }

    .toast__button {
      &__text {
        color: var(--color-caution-600);
      }

      &__icon > * {
        fill: var(--color-caution-600);
      }
    }
  }

  &--var-success {
    &:before {
      background-color: var(--color-green-200);
    }

    .toast__icon > * {
      fill: var(--color-green-400);
    }

    .toast__button {
      &__text {
        color: var(--color-green-600);
      }

      &__icon > * {
        fill: var(--color-green-600);
      }
    }
  }

  &--var-info {
    &:before {
      background-color: var(--color-info-200);
    }

    .toast__icon > * {
      fill: var(--color-info-400);
    }

    .toast__button {
      &__text {
        color: var(--color-info-600);
      }

      &__icon > * {
        fill: var(--color-info-600);
      }
    }
  }

  &--var-1 {
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-gray-200);
    box-shadow: initial;
  }
}