.toggle-switch {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &__original-input {
    display: none;
  }

  &__custom-input {
    background-color: var(--color-gray-700);
    border-radius: 12px;
    width: 48px;
    height: 24px;
    position: relative;
    transition: var(--transition-fast);

    &:after {
      content: '';
      background-color: var(--color-white);
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin: auto 0px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 3px;
      transition: var(--transition-fast);
    }
  }

  &__original-input:checked + &__custom-input {
    background-color: var(--color-main);

    &:after {
      transform: translateX(24px);
    }
  }

  &--size-small {
    .toggle-switch__custom-input {
      width: 32px;
      height: 16px;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        left: 2px;
      }
    }

    .toggle-switch__original-input:checked + .toggle-switch__custom-input {
      &:after {
        transform: translateX(16px);
      }
    }
  }

  &--color-dark-orange {
    .toggle-switch__custom-input {
      background-color: var(--brand-orange-600);
    }

    .toggle-switch__original-input:checked + .toggle-switch__custom-input {
      background-color: var(--color-white);

      &:after {
        background-color: var(--color-main);
      }
    }
  }
}
