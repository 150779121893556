.service-card {
  background: var(--color-white);
  border: 1px solid var(--Neutral-200);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-fast);

  &:hover {
    border-color: var(--color-main);
    box-shadow: 0px;

    .title {
      color: var(--color-main);
    }
  }

  .title {
    word-wrap: break-word;
    width: 102.5%;
    position: relative;
    transition: var(--transition-fast);

    &:before {
      content: '';
      background-color: var(--color-main);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 5px;
      height: 30px;
      position: absolute;
      top: -2px;
      left: -25px;
    }
  }

  .text {
    margin-top: 8px;
    color: var(--Neutral-Secondary);
  }

  .price {
    margin-top: 24px;

    &__text-before-price {
      margin-right: 4px;
    }

    &__text-free {
      // color: var(--color-gray-700);
      color: var(--Text-onLight-Neutral-Primary, #202145);
    }
  }

  .custom-pre-footer {
    margin-top: 24px;
  }

  .button {
    margin-top: 24px;
  }

  &--size-small {
    .title > * {
      font-size: 20px;
    }

    .text {
      margin-top: 8px;
    }
  }

  &--disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }
}
