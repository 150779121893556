.section {
  color: var(--color-white);

  :global(.background) {
    background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);
    margin-top: calc(0px - var(--header-height) - var(--header-bar-height));
    height: calc(100% + var(--header-height) + var(--header-bar-height));
    // min-height: 110vh;
  }

  .background__inner {
    width: 1920px;
    width: 2554px;
    height: 100%;
    margin: 0px auto;
    position: absolute;
    right: -200%;
    left: -200%;
  }

  .bg-1 {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .anim-controls {
    color: var(--color-black);
    background-color: #ffffff;
    border-radius: 8px;
    width: 320px;
    height: auto;
    padding: 24px;
    position: absolute;
    top: 30vh;
    left: 24px;
    z-index: 111;

    label {
      display: block;
    }

    input {
      width: 100%;
    }
  }

  .canvas {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .canvas-2 {
    // opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .s__3d-scene-outer {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .s__3d-scene-shake {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .s__bg-outer {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .s__bg {
    width: 2442.8px;
    height: 1717.7px;
    margin: auto;
    position: absolute;
    top: calc(-200% - 320px);
    right: calc(-200% - 187px);
    bottom: calc(-200% - 0px);
    left: calc(-200% - 0px);
  }

  .s__3d-scene {
    width: 2560px;
    height: 1440px;
    margin: auto;
    position: absolute;
    inset: -200%;
  }

  .wrapper-container {
    padding-top: calc(var(--header-height) + var(--header-bar-height));
    margin-top: calc(0px - var(--header-height) - var(--header-bar-height));
    position: relative;
    // z-index: 111; //THIS MAKES ROCKET GO ABOVE HEADER
    overflow: hidden;
  }

  .main-wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
    min-height: 690px;
    // min-height: 940px;
    // min-height: 880px;
    // max-height: 1280px;
    max-height: 1600px;
    padding-top: calc(var(--header-height) + var(--header-bar-height));
    padding-bottom: initial;
    margin-top: calc(0px - var(--header-height) - var(--header-bar-height));
  }

  .content {
    width: 100%;
    margin-top: -12vh;
  }

  .content-2 {
    display: none;
  }

  &--mark-tag-color-blue {
    mark {
      color: var(--color-light-blue);
    }
  }

  .block-list {
    display: flex;
    align-items: center;
    position: relative;

    &__item {
      position: relative;
      // outline: 1px solid red;

      &:nth-child(1) {
        flex: 1 0 auto;
        width: 50%;
        max-width: 640px;
        min-height: 100px;
      }

      &:nth-child(2) {
        height: 350px;
        flex: 1 0 auto;
      }
    }
  }

  .title {
    &__mark {
      text-decoration: underline;
      color: var(--color-cyan);
      position: relative;
      cursor: pointer;
      user-select: none;

      &__line {
        background-color: var(--color-cyan);
        width: 100%;
        height: 6px;
        position: absolute;
        bottom: -10px;
        left: 0px;
      }
    }
  }

  .text {
    margin-top: 24px;

    p:not(:nth-child(1)) {
      margin-top: 40px;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-top: 64px;
    position: relative;
    left: -8px;

    &__item {
      margin-right: 8px;
      margin-left: 8px;
    }

    &--var-1 {
      margin-top: 48px;

      .button-group__item {
        & > * {
          min-width: 170px;

          & > * {
            min-width: 170px;
          }
        }
      }
    }
  }

  .text-for-popup {
    margin-top: 16px;
  }

  .s__sec-title {
    color: var(--color-main);
    margin-bottom: 26px;
  }

  .s__text {
    margin-top: 26px;
  }

  .s__content {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 66;

    .s__wrapper {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .s__top-block-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .planet {
    width: 519px;
    margin: 0px auto;
    position: absolute;
    top: 330px;
    right: -100%;
    left: -100%;
  }

  .astronaut {
    width: 62px;
    // width: 185px;
    margin: 0px auto;
    position: absolute;
    top: 279px;
    right: -100%;
    left: calc(-100% - 194px);
    z-index: 55;
  }

  .orbit {
    width: 558px;
    height: 558px;
    margin: 0px auto;
    position: absolute;
    top: -34px;
    right: -100%;
    left: -100%;

    &__obj-container-one {
      background: rgba(123, 122, 163, 0.06);
      border: 2px dashed rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      width: 558px;
      height: 558px;
      margin: auto;
      position: absolute;
      top: -200%;
      right: -200%;
      bottom: -200%;
      left: -200%;
      transform-origin: center;
    }

    &__obj-container-two {
      background: rgba(123, 122, 163, 0.06);
      border: 2px dashed rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      width: 342px;
      height: 342px;
      margin: auto;
      position: absolute;
      top: -200%;
      right: -200%;
      bottom: -200%;
      left: -200%;
      transform-origin: center;
    }

    &__one {
      width: 558px;
      height: 558px;
      margin: auto;
      position: absolute;
      top: -200%;
      right: -200%;
      bottom: -200%;
      left: -200%;
      transform-origin: center;
    }

    &__two {
      width: 342px;
      height: 342px;
      margin: auto;
      position: absolute;
      top: -200%;
      right: -200%;
      bottom: -200%;
      left: -200%;
      transform-origin: center;
    }

    &__planet {
      width: 15px;
      height: 15px;
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &__dot {
        background-color: #353a88;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-right: auto;
        margin-left: auto;
      }

      &__text {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
      }

      &--1 {
        top: 60%;
        left: 0%;
      }

      &--2 {
        top: 22%;
        left: 6%;
      }

      &--3 {
        top: 17%;
        left: 88%;
      }

      &--4 {
        top: 40%;
        left: 98%;
      }

      &--5 {
        top: 68%;
        left: 95%;
      }

      &--6 {
        top: 38%;
        left: -1.5%;
      }

      &--7 {
        top: 62%;
        left: 96%;
      }
    }
  }

  .moon {
    width: 46px;
    margin: 0px auto;
    position: absolute;
    top: -60px;
    right: -100%;
    left: -100%;
    z-index: 55;
  }

  .label-group {
    width: 558px;
    height: 558px;
    margin: 0px auto;
    position: absolute;
    top: -34px;
    right: -100%;
    left: -100%;

    .label {
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      text-align: center;
      color: #fff;
      border-radius: 0px 4px;
      background: linear-gradient(90deg, rgba(115, 161, 255, 0.2) 0%, rgba(82, 253, 234, 0.2) 72.19%);
      display: inline-block;
      padding: 5px 10px;
      position: absolute;

      &--1 {
        top: 3%;
        left: 53.7%;
      }

      &--2 {
        top: 40%;
        left: 62%;
      }

      &--3 {
        top: 65%;
        left: 19%;
      }
    }
  }

  .rocket {
    margin-top: 40px;
    position: relative;
    z-index: 66;
    pointer-events: none;

    &__obj {
      width: 190px;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      z-index: 55;

      &__inner {
        position: relative;
        z-index: 66;
      }

      svg {
        overflow: visible;

        // transform: translateY(-200px);
      }
    }

    &__smoke {
      // opacity: 0;
      width: 3235px;
      margin: 0px auto;
      position: absolute;
      top: calc(100% - 75px);
      // top: calc(100% - 420px);
      left: -1877px;
      right: 0px;
      z-index: 55;

      &--for-launch {
        // opacity: 1;
        // opacity: 0;
        // width: 3945px;
        width: 160px;
        top: calc(100% - 656px);
        // top: calc(100% - 1167px);
        // top: calc(100% - 1147px);
        left: 0px;
        // left: -2228px;
        z-index: -1;
        filter: blur(2px);
      }

      svg {
        path {
          fill: var(--Background-Neutral-Primary);
        }
      }
    }

    .rocket__fire-1 {
      opacity: 0;
      background: linear-gradient(180deg, #faaf1c 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 50%;
      border-top-right-radius: 30%;
      border-top-left-radius: 30%;
      width: 20px;
      height: 250px;
      margin: 0px auto;
      position: absolute;
      top: calc(100% - 100px);
      right: 0px;
      left: 0px;
      z-index: -1;

      &:before {
        content: '';
        background: linear-gradient(180deg, #faaf1c 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(20px);
      }

      &:after {
        content: '';
        background: linear-gradient(180deg, #faaf1c 0%, rgba(255, 255, 255, 0) 100%);
        width: 20%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(1px) brightness(110%);
      }
    }

    .rocket__fire-2 {
      opacity: 0;
      background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 50%;
      border-top-right-radius: 30%;
      border-top-left-radius: 30%;
      width: 10px;
      height: 150px;
      margin: 0px auto;
      position: absolute;
      top: calc(100% - 100px);
      right: 0px;
      left: -90px;
      z-index: -1;

      &:before {
        content: '';
        background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(20px);
      }

      &:after {
        content: '';
        background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
        width: 20%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(1px) brightness(110%);
      }
    }

    .rocket__fire-3 {
      opacity: 0;
      background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 50%;
      border-top-right-radius: 30%;
      border-top-left-radius: 30%;
      width: 10px;
      height: 150px;
      margin: 0px auto;
      position: absolute;
      top: calc(100% - 100px);
      right: -90px;
      left: 0px;
      z-index: -1;

      &:before {
        content: '';
        background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(20px);
      }

      &:after {
        content: '';
        background: linear-gradient(180deg, #78d1f4 0%, rgba(255, 255, 255, 0) 100%);
        width: 20%;
        height: 100%;
        margin: 0px auto;
        position: absolute;
        right: -200%;
        left: -200%;
        filter: blur(1px) brightness(110%);
      }
    }
  }

  .content-in-smoke {
    text-align: center;
    width: 286px;
    margin: 0px auto;
    position: absolute;
    bottom: -210px;
    right: -100%;
    left: -100%;
    z-index: 77;

    &__button {
      background-color: var(--color-main);
      outline: none;
      border: 2px solid var(--color-main);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      cursor: pointer;
      transition: var(--transition-fast);

      &__arrow {
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 55;
        transition: var(--transition-fast);

        &__vertical {
          background-color: var(--color-white);
          border-radius: 24px;
          width: 3px;
          height: 24px;
          margin: 0px auto;
          position: absolute;
          top: 0px;
          right: 0px;
          left: 0px;
          transition: var(--transition-fast);
        }

        &__diag-1 {
          background-color: var(--color-white);
          border-radius: 24px;
          width: 3px;
          height: 17px;
          margin: 0px auto;
          position: absolute;
          top: 0px;
          right: 0px;
          left: 0px;
          transform-origin: top center;
          transform: rotate(45deg) translateX(1px) translateY(1px);
          transition: var(--transition-fast);
        }

        &__diag-2 {
          background-color: var(--color-white);
          border-radius: 24px;
          width: 3px;
          height: 17px;
          margin: 0px auto;
          position: absolute;
          top: 0px;
          right: 0px;
          left: 0px;
          transform-origin: top center;
          transform: rotate(-45deg) translateX(-1px) translateY(1px);
          transition: var(--transition-fast);
        }
      }

      @keyframes buttonCirclePulses {
        0% {
          opacity: 0;
          transform: scale(0.66);
        }

        25% {
          opacity: 0.75;
          transform: scale(1.25);
        }

        50% {
          opacity: 0;
          transform: scale(1.5);
        }

        100% {
          opacity: 0;
          transform: scale(1.5);
        }
      }

      &__circles-outer {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }

      &__circle-1 {
        background-color: var(--color-main);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        transform-origin: center;
        animation: 2.5s buttonCirclePulses infinite linear;
      }

      &__circle-2 {
        background-color: var(--color-main);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        transform-origin: center;
        animation: 2.5s buttonCirclePulses infinite linear;
        animation-delay: 0.5s;
      }

      &:hover {
        background-color: var(--color-white);

        .content-in-smoke__button__arrow {
          transform: translateY(-2px);
        }

        .content-in-smoke__button__arrow__vertical {
          background-color: var(--color-main);
        }

        .content-in-smoke__button__arrow__diag-1 {
          background-color: var(--color-main);
          transform: rotate(40deg) translateX(0px) translateY(-1px);
        }

        .content-in-smoke__button__arrow__diag-2 {
          background-color: var(--color-main);
          transform: rotate(-40deg) translateX(0px) translateY(-1px);
        }
      }
    }

    &__text-1 {
      color: var(--color-main);
      margin-top: 8px;
    }

    &__text-2 {
      font-size: 16px;
      font-weight: 800;
      line-height: 150%;
      color: var(--color-gray-900);
      margin-top: 20px;
    }

    &__text-3 {
      color: var(--color-gray-800);
      min-height: 54px;
      margin-top: 8px;
    }
  }

  &--skip-to-rocket {
    visibility: hidden;

    .s__content {
      visibility: visible;
    }

    .rocket {
      visibility: visible;
    }

    .rocket__smoke {
      visibility: hidden;
    }

    .content-2 {
      visibility: visible;
    }

    :global(.background) {
      visibility: visible;
    }
  }
}

@media screen and (max-width: 1366px) {
  .section {
    .content {
      margin-top: -8vh;
    }

    .content-in-smoke {
      bottom: -132px;

      &__text-2 {
        margin-top: 8px;
      }

      &__text-3 {
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .section {
    .main-wrapper {
      height: auto;
      min-height: 100vh;
      max-height: initial;
    }

    .content {
      margin-top: 40px;
    }

    .content-2 {
      margin-bottom: -100px;
      position: relative;
      z-index: 55;
    }

    .block-list {
      flex-wrap: wrap;

      &__item {
        &:nth-child(1) {
          width: 100%;
          max-width: initial;
          min-height: 100px;
        }

        &:nth-child(2) {
          width: 100%;
          height: 580px;
          margin-top: 110px;
        }
      }
    }

    .button-group {
      &__item {
        width: 100%;

        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        & > * {
          width: 100%;

          & > * {
            width: 100%;
          }
        }
      }
    }

    .content-in-smoke {
      bottom: 60px;
    }

    .s__content {
      .s__top-block-list {
        &__item {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }

    .s__3d-scene-outer {
      left: -168px;
    }
  }
}
