.input {
  &__label {
    margin-bottom: 4px;
  }

  &__container {
    align-items: center;
    position: relative;

    &:after {
      content: '';
      border: 2px solid var(--color-main);
      border-radius: 8px;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      pointer-events: none;
      box-sizing: border-box;
      transition: var(--transition-fast);
    }

    input {
      font-family: 'Axiforma', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: initial;
      color: var(--Neutral-Primary);
      outline: none;
      border-radius: 8px;
      border: 1px solid var(--Neutral-200);
      background: var(--color-white);
      display: block;
      width: 100%;
      padding-top: 12px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 16px;
      box-shadow: var(--shadow-xs);
      transition: var(--transition-fast);

      &::placeholder {
        color: var(--Text-onLight-Neutral-Tertiary);
      }
    }

    &__icon-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: auto 0px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 16px;

      svg {
        fill: var(--color-gray-700);
        transition: var(--transition-fast);
      }
    }

    &__icon-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: auto 0px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 16px;
      transition: var(--transition-fast);

      svg {
        fill: var(--color-gray-700);
        transition: var(--transition-fast);
      }
    }

    &--icon-left {
      input {
        padding-left: 48px;
      }
    }

    &--icon-right {
      input {
        padding-right: 48px;
      }
    }
  }

  &--size-large {
    .input__container {
      input {
        padding-top: 16px;
        padding-bottom: 14px;
      }
    }
  }

  &:hover {
    .input__container {
      input {
        border-color: var(--color-orange);
      }

      &__icon-left,
      &__icon-right {
        svg {
          fill: var(--color-black);
        }
      }
    }
  }

  &.input--has-error {
    .input__container {
      input {
        border-color: var(--color-red-400);
      }
    }
  }

  &.input--is-focused {
    .input__container {
      &:after {
        opacity: 1;
      }

      &__icon-left {
        svg {
          fill: var(--brand-orange-600);
        }
      }
    }
  }

  &.input--color-gray {
    input {
      border: 1px solid rgba(var(--Neutral-0-rgb), 0.5);
      background: rgba(var(--Neutral-0-rgb), 0.1);
      color: var(--color-white);

      &::placeholder {
        color: rgba(var(--Neutral-0-rgb), 0.6);
      }
    }

    &:hover {
      .input__container {
        input {
          border-color: rgba(var(--Neutral-0-rgb), 1);
          background: rgba(var(--Neutral-0-rgb), 0.25);
        }

        &__icon-left,
        &__icon-right {
          svg {
            fill: rgba(var(--Neutral-0-rgb), 0.25);
          }
        }
      }
    }

    &.input--is-focused {
      .input__container {
        &:after {
          opacity: 1;
        }

        input {
          color: var(--Neutral-Primary);
          background-color: var(--Neutral-0);

          &::placeholder {
            color: rgba(var(--Neutral-Primary-rba), 0.8);
          }
        }

        &__icon-left {
          svg {
            fill: var(--brand-orange-600);
          }
        }

        &__icon-right {
          svg {
            fill: var(--brand-orange-600);
          }
        }
      }
    }
  }

  &.input--is-disabled {
    pointer-events: none;

    .input__container {
      filter: grayscale(100%);
      opacity: 0.5;

      input {
        box-shadow: 0px;
      }
    }
  }
}
