.section-2 {
  color: var(--color-white);

  mark {
    color: var(--Text-Brand-Cyan-Default);
  }

  .text-1 {
    color: var(--Text-onDark-Neutral-Secondary);
  }

  .text-2 {
    color: var(--Text-Brand-Cyan-Default);
  }

  .text-3 {
    color: var(--Text-onDark-Neutral-Secondary);
    margin-top: 8px;
  }

  .text-blocks-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .middle-part {
    &__block-list {
      display: flex;
      gap: 16px;

      &__item {
        width: calc(50% - 8px);
      }
    }
  }

  .ill {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .bottom-part {
    display: flex;
    justify-content: center;
    margin-top: 64px;

    &__list {
      flex-wrap: wrap;
    }
  }
}

.section-divider {
  background-color: var(--color-space-dark);
  padding-top: 24px;
  padding-bottom: 24px;

  &__line {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 55;

    > * {
      display: block;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--var-a {
    background-color: initial;
  }
}

.section-3 {
  color: var(--color-white);

  .title {
    text-align: center;
  }

  .title-2 {
    text-align: center;
    margin-bottom: 24px;
  }

  .text-2 {
    margin-top: 40px;
  }

  .text-3 {
    color: var(--Text-onDark-Neutral-Secondary);
    margin-top: 8px;
  }

  .block-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 64px;

    &__item {
      width: calc(33.33% - 11px);
      position: relative;
      overflow: hidden;

      &__icon-on-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 208px;
        height: 208px;
        position: absolute;
        bottom: -50px;
        right: -50px;

        & > * {
          opacity: 0.033;
          width: 90%;
          height: auto;
          max-height: 90%;
        }
      }

      &__icon {
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;

        & > * {
          max-width: 95%;
          max-height: 95%;
        }
      }

      &--var-a {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

.section-group-1 {
  background: linear-gradient(356deg, #23286f 3.4%, #0c0f3c 96.57%);
  position: relative;
  overflow: hidden;

  .bg-1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;

    & > * {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.section-4 {
  color: var(--color-white);

  .text-1 {
    color: var(--Text-onDark-Neutral-Secondary);
  }

  .ill {
    margin-top: 36px;

    & > * {
      display: block;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.section-5 {
  color: var(--color-white);

  mark {
    color: var(--Text-Brand-Cyan-Default);
  }

  .button {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .section-2 {
    .middle-part {
      &__block-list {
        flex-wrap: wrap;

        &__item {
          width: 100%;
        }
      }
    }

    .bottom-part {
      margin-top: 40px;

      > * {
        width: 100%;

        > * {
          width: 100%;

          > * {
            width: 100%;
          }
        }
      }
    }
  }

  .section-3 {
    .block-list {
      margin-top: 40px;

      &__item {
        width: 100%;
      }
    }
  }
}
