.section {
  color: var(--color-white);
  height: 100vh;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background-color: #222880;
    background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);
    height: 200vh;
    width: 200vw;
    margin: auto;
    position: fixed;
    inset: -1000%;
    z-index: -1;
    pointer-events: none;
  }

  svg {
    overflow: visible;
    transform-origin: center;
  }

  .background {
    // background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);
    // background-color: #222880;
    z-index: -1;

    .bg-space {
      background: rgb(34, 40, 128);
      background: linear-gradient(180deg, rgba(2, 3, 24, 1) 0%, rgba(34, 40, 128, 1) 100%);
      width: 100%;
      height: calc(100vh * 6 * 2);
      position: absolute;
      bottom: 0px;
      left: 0px;

      &__confetti {
        width: 100%;
      }
    }

    .slide-list {
      &__item {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }

    .slide-1__bg-1 {
      display: block;
      margin: auto;
      width: 2560px;
      max-width: initial;
      height: 100%;
      position: absolute;
      inset: -2000%;
      z-index: 55;
    }

    .slide-1_bg-1-2 {
      background-color: #1d2163;
      width: 100%;
      height: 19vh;
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 45;
    }

    .slide-1__bg-2 {
      display: block;
      width: 1440px;
      max-width: initial;
      margin: 0px auto;
      position: absolute;
      right: -200%;
      bottom: 160px;
      left: -200%;
      z-index: 44;
    }

    .slide-1__bg-3 {
      display: block;
      margin: auto;
      position: absolute;
      inset: -200%;
      bottom: -235%;
      z-index: 44;
    }

    .slide-1__bg-4 {
      display: block;
      margin: auto;
      position: absolute;
      inset: -200%;
      bottom: -230%;
      z-index: 44;
    }

    .slide-3__bg-1 {
      display: block;
      width: 812px;
      margin: auto;
      position: absolute;
      inset: -200%;
      top: -230%;
      left: -205%;
      z-index: 44;
    }

    .slide-6__bg-1 {
      display: block;
      width: 722px;
      margin: auto;
      position: absolute;
      inset: -200%;
      bottom: -220%;
      left: -196%;
      z-index: 44;
    }

    .slide-6__bg-2 {
      display: block;
      width: 1440px;
      margin: auto;
      position: absolute;
      inset: -200%;
      bottom: -218%;
      left: -197%;
      z-index: 44;
    }
  }

  .header {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 55;

    :global(.wrapper) {
      padding-bottom: initial;
    }

    &__layout {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__block-1 {
      }

      &__block-2 {
      }
    }
  }

  .footer {
    width: 100%;
    height: 146px;
    margin: auto 0px;
    position: absolute;
    top: 0px;
    bottom: -630px;
    left: 0px;
    pointer-events: none;

    :global(.wrapper) {
      padding-bottom: initial;
    }

    .scroll-tip {
      text-align: center;
      pointer-events: all;

      &__icon {
        width: 20px;
        margin-top: 40px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .aside {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;

    :global(.wrapper) {
      padding-top: initial;
      padding-bottom: initial;
    }

    .content {
      position: relative;
    }

    .container {
      display: flex;
      align-items: center;
      height: 100vh;
    }

    .step-list {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 48px;

      &__item {
        text-align: right;
        width: 100%;
        position: relative;
        padding-top: 18px;
        padding-bottom: 16px;
        cursor: pointer;
        pointer-events: all;

        &:not(:nth-child(1)) {
          // margin-top: 18px;
        }

        &:before {
          content: '';
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 6px;
          display: block;
          width: 24px;
          height: 2px;
          position: absolute;
          top: 0px;
          right: 0px;
          transition: var(--transition-fast);
        }

        &:after {
          content: '';
          background-color: rgba(255, 255, 255, 0.25);
          border-radius: 6px;
          display: block;
          width: 12px;
          height: 1px;
          position: absolute;
          bottom: 24px;
          right: 0px;
          transition: var(--transition-fast);
        }

        &__text {
          font-size: 16px;
          font-weight: 500;
          line-height: initial;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          color: var(--color-white);
          opacity: 0;
          transform: translateX(-6px);
          transition: var(--transition-fast);
        }

        &:hover {
          &:before {
            width: 40px;
          }

          &:after {
            opacity: 0;
            width: 0px;
          }

          .step-list__item__text {
            opacity: 0.75;
            transform: translateX(0px);
          }
        }

        &--active {
          &.step-list__item:before {
            background-color: var(--color-white);
            width: 48px;
          }

          &.step-list__item:after {
            opacity: 0;
            width: 0px;
          }

          &.step-list__item .step-list__item__text {
            opacity: 1;
            transform: translateX(0px);
          }
        }
      }
    }
  }

  .main {
    :global(.wrapper) {
      padding-top: initial;
      padding-bottom: initial;
    }

    .content {
      padding-left: 116px;
      padding-right: 116px;
    }

    .container {
    }

    .slide-list {
      position: relative;

      &__item {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        pointer-events: none;

        &__container {
          height: 100vh;
          padding-top: 120px;
          padding-bottom: 120px;
        }
      }

      &__item-1 {
        text-align: center;

        .slide-list__item__container {
          display: flex;
          align-items: center;

          .slide-list__item__content {
            max-width: 680px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
          }
        }

        .text-2 {
          color: var(--color-cyan);
          margin-top: 32px;
        }

        .text-3 {
          margin-top: 16px;
        }
      }

      &__item-2 {
        .slide-list__item__container {
          display: flex;
          align-items: center;
        }

        .slide-list__item__layout {
          display: flex;
          align-items: center;

          &__item-1 {
            max-width: 543px;
            position: relative;
          }

          &__item-2 {
            width: calc(100% - 543px - 32px);
            margin-left: 32px;
            position: relative;
          }
        }

        .ill {
          min-width: 618px;
          position: relative;
          right: -24px;
        }

        .shape {
          min-width: 742px;
          position: absolute;
          top: -210px;
          right: -144px;
          z-index: -1;
        }

        .text-1 {
          mark {
            color: var(--color-cyan);
          }
        }

        .text-2 {
          margin-top: 32px;
        }
      }

      &__item-3 {
        text-align: center;

        .slide-list__item__container {
          display: flex;
          align-items: center;

          .slide-list__item__content {
            max-width: 894px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
          }
        }

        .text-1 {
          mark {
            color: var(--color-cyan);
          }
        }

        .text-2 {
          margin-top: -18px;
        }

        .person-group {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          position: relative;
        }

        .person-1 {
          position: relative;
          top: -30px;
          left: -10px;
        }

        .person-2 {
          position: relative;
          top: -50px;
          left: 10px;
        }

        .light {
          margin: auto;
          position: absolute;
          top: -180px;
          right: 0px;
          bottom: 0px;
          left: -60px;
        }

        .line {
          width: 624px;
          max-width: initial;
          margin: 0px auto;
          position: absolute;
          top: -310px;
          right: -200%;
          left: -171%;
          z-index: -1;
        }

        .shape {
          width: 1440px;
          max-width: initial;
          margin: 0px auto;
          position: absolute;
          top: -80px;
          right: -200%;
          left: -200%;
          z-index: -1;
        }
      }

      &__item-4 {
        .slide-list__item__container {
          display: flex;
          align-items: center;
        }

        .slide-list__item__layout {
          display: flex;
          align-items: center;

          &__item-1 {
            max-width: 543px;
            position: relative;
          }

          &__item-2 {
            width: calc(100% - 543px - 32px);
            margin-left: 32px;
            position: relative;
          }
        }

        .ill {
          min-width: 754px;
          position: relative;
          top: -11px;
          left: -78px;
        }

        .text-1 {
          mark {
            color: var(--color-cyan);
          }
        }

        .text-2 {
          margin-top: 32px;
        }

        .button {
          margin-top: 40px;
        }
      }

      &__item-5 {
        .slide-list__item__container {
          display: flex;
          align-items: center;
        }

        .slide-list__item__layout {
          display: flex;
          align-items: center;

          &__item-1 {
            width: calc(100% - 543px - 32px);
            margin-right: 32px;
            position: relative;
          }

          &__item-2 {
            max-width: 543px;
            position: relative;
            right: -163px;
          }
        }

        .ill {
          min-width: 611px;
          position: relative;
          top: -120px;
          left: 0px;
        }

        .text-1 {
        }

        .text-2 {
          margin-top: 32px;

          mark {
            color: var(--color-cyan);
          }
        }
      }

      &__item-6 {
        text-align: center;

        .slide-list__item__container {
          display: flex;
          align-items: center;

          .slide-list__item__content {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            position: relative;
          }
        }

        .text-outer {
          max-width: 863px;
          margin-right: auto;
          margin-left: auto;
          position: relative;
          left: 24px;
        }

        .text-1 {
        }

        .text-2 {
          margin-top: 32px;
        }

        .button-outer {
          margin-top: 56px;
          position: relative;
          left: 24px;
        }

        .fuel-group {
          display: flex;
          justify-content: center;
          width: calc(100% + 61px);
          gap: 32px;
          margin-top: 80px;
          margin-right: auto;
          margin-left: auto;

          &__item {
            text-align: left;
            width: 33.33%;
            height: 219px;
            padding: 26px 0px 0px 100px;
            position: relative;

            &__ill {
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: -1;
            }

            &__percent {
              color: #292e6d;
              font-size: 28px;
              font-weight: 800;
              letter-spacing: -0.28px;
              text-align: center;
              background-color: var(--color-white);
              border-radius: 18px;
              display: flex;
              width: 64px;
              height: 64px;
              justify-content: center;
              align-items: center;
            }

            &__sub-title {
              margin-top: 24px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .section {
    .background {
      .slide-1_bg-1-2 {
        height: 10vh;
      }
    }

    .main {
      .slide-list__item-2 {
        .ill {
          right: 40px;
        }
      }

      .slide-list__item-5 {
        .slide-list__item__layout__item-2 {
          max-width: 484px;
        }

        .ill {
          top: -60px;
        }
      }

      .slide-list__item-6 {
        .text-2 {
          margin-top: 24px;
        }

        .button-outer {
          margin-top: 24px;
        }

        .fuel-group {
          gap: 24px;
          margin-top: 40px;

          &__item {
            width: 33.33%;
            padding-left: 80px;
            position: relative;
          }
        }
      }
    }

    .footer {
      top: initial;
      bottom: 24px;

      .scroll-tip {
        &__text {
          font-size: 14px;
        }

        &__icon {
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .section {
    height: auto;

    .background {
      .bg-space {
        background: linear-gradient(0deg, rgba(2, 3, 24, 1) 0%, rgba(34, 40, 128, 1) 100%);
        height: 100%;
        transform: translateY(0px) !important;
      }

      .slide-1_bg-1-2 {
        background: linear-gradient(180deg, rgba(29, 33, 99, 1) 0%, rgba(30, 35, 113, 1) 100%);
        height: 7vh;
      }

      .slide-list {
        position: relative;
        height: 100vh;
        overflow: hidden;

        &__item {
          opacity: 1;
        }
      }
    }

    .header {
      background-color: rgba(34, 40, 128, 0.6);
      backdrop-filter: blur(6px);

      position: fixed;

      :global(.wrapper) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .main {
      .content {
        padding-left: initial;
        padding-right: initial;
      }

      .slide-list {
        position: relative;

        &__item {
          opacity: 1;
          width: 100%;
          height: auto;
          position: relative;
          pointer-events: all;

          &__container {
            height: auto;
            min-height: 100vh;
            padding-top: 80px;
            padding-bottom: 80px;
          }
        }

        &__item-2 {
          .slide-list__item__layout {
            flex-wrap: wrap;
            gap: 40px;

            &__item-1 {
              max-width: 100%;
              width: 100%;
            }

            &__item-2 {
              width: 100%;
              margin-left: initial;
            }
          }

          .ill {
            min-width: initial;
            max-width: 618px;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            right: -1vw;
          }

          .shape {
            top: -340px;
          }
        }

        &__item-3 {
          .slide-list__item__container {
            .slide-list__item__content {
            }
          }

          .text-1 {
            mark {
            }
          }

          .text-2 {
          }

          .person-group {
          }

          .person-1 {
          }

          .person-2 {
          }

          .light {
          }

          .line {
          }

          .shape {
          }
        }

        &__item-4 {
          .slide-list__item__container {
            .slide-list__item__content {
              width: 100%;
            }
          }

          .slide-list__item__layout {
            flex-wrap: wrap;
            gap: 40px;

            &__item-1 {
              max-width: 100%;
              width: 100%;
            }

            &__item-2 {
              width: 100%;
              margin-left: initial;
            }
          }

          .ill {
            margin-top: -280px;
            margin-bottom: -240px;
            margin-right: auto;
            margin-left: auto;
            top: initial;
            left: initial;
          }

          .text-1 {
          }

          .text-2 {
          }
        }

        &__item-5 {
          .slide-list__item__container {
            .slide-list__item__content {
              width: 100%;
            }
          }

          .slide-list__item__layout {
            flex-wrap: wrap;
            gap: 40px;

            &__item-1 {
              width: 100%;
              margin-top: -120px;
              margin-right: initial;
            }

            &__item-2 {
              max-width: 100%;
              position: relative;
              right: initial;
              order: -1;
            }
          }

          .ill {
            margin-right: auto;
            margin-left: auto;
            top: initial;
            left: 0px;
          }

          .text-1 {
          }

          .text-2 {
          }
        }

        &__item-6 {
          .slide-list__item__container {
            padding-bottom: 16px;

            .slide-list__item__content {
            }
          }

          .text-outer {
            left: initial;
          }

          .text-1 {
          }

          .text-2 {
            margin-top: 32px;
          }

          .button-outer {
            margin-top: 60px;
            left: initial;

            & > * {
              width: 100%;
            }
          }

          .fuel-group {
            flex-wrap: wrap;
            width: 100%;
            max-width: 359px;
            margin-right: auto;
            margin-left: auto;
            gap: 32px;

            &__item {
              width: 100%;
              padding-left: 96px;

              &__ill {
                width: 359px;
                height: 220px;
              }

              &__percent {
              }

              &__sub-title {
              }
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;
      height: 100vh;
      top: 0px;
      bottom: initial;

      :global(.wrapper) {
        position: relative;
        top: -80px;
      }

      .scroll-tip {
        &__text {
          font-size: 20px;
        }

        &__icon {
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .section {
    .main {
      .slide-list {
        &__item-3 {
          .light {
            left: -50px;
          }

          .line {
            left: -160%;
          }
        }

        &__item-4 {
          .ill {
            left: -78px;
          }
        }

        &__item-5 {
          .ill {
            left: -120px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .section {
    .main {
      .slide-list {
        &__item-3 {
          .light {
            left: -1vw;
          }

          .line {
            left: -145%;
          }
        }
      }
    }
  }
}
