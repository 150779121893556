.section {
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  :global(.wrapper) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .logo {
    display: block;
    width: 194px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    right: 0px;
    left: 0px;
  }

  :global(.content) {
    color: var(--color-white);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 24px - 24px);
    position: relative;

    .info {
      max-width: 100%;
      position: relative;
    }

    .text {
      margin-top: 16px;
    }
    
    .loading-bar {
      background-color: var(--color-white-25);
      border-radius: 12px;
      width: 640px;
      max-width: 100%;
      height: 8px;
      margin-top: 78px;
      position: relative;
      overflow: hidden;

      &__progress {
        background-color: var(--color-main);
        border-radius: 12px;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;

        transition: 0.2s linear;
      }
    }
  }
}