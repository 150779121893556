.widget {
  position: relative;

  &__inner {
    display: flex;
    gap: 32px;
  }

  &__property {
    color: var(--Text-onLight-Neutral-Secondary, #5e617b);
    font-size: 10px;
    font-weight: 700;
    line-height: 160%;
    margin-bottom: 4px;
  }

  &__value-container {
    display: flex;
    align-items: flex-end;
  }

  &__value {
    color: var(--Text-onLight-Neutral-Primary, #202145);
    font-size: 16px;
    font-weight: 800;
    line-height: 150%;

    &--color-main {
      color: var(--color-main);
    }
  }

  &__value-after {
    color: var(--Text-onLight-Neutral-Secondary, #5e617b);
    font-size: 10px;
    font-weight: 500;
    line-height: 150%;
    margin-left: 4px;
    position: relative;
    top: -2px;
  }

  &--size-var-a {
    .widget__property {
      font-size: 12px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }

    .widget__value {
      font-size: 32px;
      font-weight: 800;
      line-height: 150%;
      letter-spacing: -0.32px;
    }

    .widget__value-after {
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      top: -10px;
    }
  }
}
