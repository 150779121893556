.apps {
  position: relative;

  .title {
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 767px) {
}
