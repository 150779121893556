.grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px * 2);
  margin-top: -32px;
  position: relative;
  left: -16px;

  &__item {
    margin-top: 32px;
    margin-right: 16px;
    margin-left: 16px;

    > * {
      height: 100%;
    }
  }

  &.grid--size-2 {
    &.grid--horizontal-margin-1 {
      width: calc(100% + 16px * 2);
      left: -16px;

      .grid__item {
        width: calc(50% - 16px * 2);
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    &.grid--horizontal-margin-2 {
      width: calc(100% + 30px * 2);
      left: -30px;

      .grid__item {
        width: calc(50% - 30px * 2);
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    &.grid--horizontal-margin-3 {
      width: calc(100% + 8px * 2);
      left: -8px;

      .grid__item {
        width: calc(50% - 8px * 2);
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  &.grid--size-3 {
    &.grid--horizontal-margin-1 {
      width: calc(100% + 16px * 2);
      left: -16px;

      .grid__item {
        width: calc(33.33% - 16px * 2);
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    &.grid--horizontal-margin-2 {
      width: calc(100% + 30px * 2);
      left: -30px;

      .grid__item {
        width: calc(33.33% - 30px * 2);
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    &.grid--horizontal-margin-3 {
      width: calc(100% + 8px * 2);
      left: -8px;

      .grid__item {
        width: calc(33.33% - 8px * 2);
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  &.grid--size-4 {
    &.grid--horizontal-margin-1 {
      width: calc(100% + 16px * 2);
      left: -16px;

      .grid__item {
        width: calc(25% - 16px * 2);
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    &.grid--horizontal-margin-2 {
      width: calc(100% + 30px * 2);
      left: -30px;

      .grid__item {
        width: calc(25% - 30px * 2);
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    &.grid--horizontal-margin-3 {
      width: calc(100% + 8px * 2);
      left: -8px;

      .grid__item {
        width: calc(25% - 8px * 2);
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  &.grid--size-5 {
    &.grid--horizontal-margin-1 {
      width: calc(100% + 16px * 2);
      left: -16px;

      .grid__item {
        width: calc(20% - 16px * 2);
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    &.grid--horizontal-margin-2 {
      width: calc(100% + 30px * 2);
      left: -30px;

      .grid__item {
        width: calc(20% - 30px * 2);
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    &.grid--horizontal-margin-3 {
      width: calc(100% + 8px * 2);
      left: -8px;

      .grid__item {
        width: calc(20% - 8px * 2);
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  &.grid--vertical-margin-1 {
    margin-top: -32px;

    .grid__item {
      margin-top: 32px;
    }
  }

  &.grid--vertical-margin-2 {
    margin-top: -60px;

    .grid__item {
      margin-top: 60px;
    }
  }

  &.grid--vertical-margin-3 {
    margin-top: -16px;

    .grid__item {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .grid {
    width: 100%;
    margin-top: initial;
    left: initial;
  
    &__item {
      width: 100%;
      margin-top: initial;
      margin-right: initial;
      margin-left: initial;
  
      > * {
        height: initial;
      }
    }
  
    &.grid--size-2 {
      &.grid--horizontal-margin-1 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-2 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-3 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
    }
  
    &.grid--size-3 {
      &.grid--horizontal-margin-1 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-2 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-3 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
    }

    &.grid--size-4 {
      &.grid--horizontal-margin-1 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-2 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-3 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
    }
  
    &.grid--size-5 {
      &.grid--horizontal-margin-1 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-2 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
  
      &.grid--horizontal-margin-3 {
        width: 100%;
        left: initial;
  
        .grid__item {
          width: 100%;
          margin-right: initial;
          margin-left: initial;
        }
      }
    }
  }
}