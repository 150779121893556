.input-layout {
  &__row {
    margin-top: 16px;
    position: relative;
    
    &:nth-child(1) {
      margin-top: initial;
    }

    &__col {
      flex: 1 0 auto;
    }

    &--medium-margin-0 {
      margin-top: 24px;
    }

    &--medium-margin {
      margin-top: 32px;
    }

    &--large-margin {
      margin-top: 40px;
    }

    &--two-col {
      display: flex;
      width: calc(100% + 16px);
      left: -8px;

      .input-layout__row__col {
        width: calc(50% - 8px - 8px);
        margin-right: 8px;
        margin-left: 8px;
      }
    }

    &--three-col {
      display: flex;
      width: calc(100% + 16px);
      left: -8px;

      .input-layout__row__col {
        width: calc(33.33% - 8px - 8px);
        margin-right: 8px;
        margin-left: 8px;
      }
    }

    &--flexible-col {
      display: flex;
      width: calc(100% + 16px);
      left: -8px;

      .input-layout__row__col {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .input-layout {
    &__row {
      &--two-col {
        flex-wrap: wrap;
        width: 100%;
        left: initial;
  
        .input-layout__row__col {
          width: 100%;
          margin-right: initial;
          margin-left: initial;

          &:not(:nth-child(1)) {
            margin-top: 16px;
          }
        }
      }

      &--three-col {
        flex-wrap: wrap;
        width: 100%;
        left: initial;
  
        .input-layout__row__col {
          width: 100%;
          margin-right: initial;
          margin-left: initial;

          &:not(:nth-child(1)) {
            margin-top: 16px;
          }
        }
      }

      &--medium-margin-0 {
        .input-layout__row__col {
          &:not(:nth-child(1)) {
            margin-top: 24px;
          }
        }
      }
  
      &--medium-margin {
        .input-layout__row__col {
          &:not(:nth-child(1)) {
            margin-top: 32px;
          }
        }
      }
  
      &--large-margin {
        .input-layout__row__col {
          &:not(:nth-child(1)) {
            margin-top: 40px;
          }
        }
      }
    }
  }
}