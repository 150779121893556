.boost {
  &__back-link {
    margin-bottom: 32px;
  }

  .boost-tile {
    background-color: var(--color-white);
    border-radius: 8px;
    border: 1px solid var(--color-gray-300);
    margin-top: 32px;

    &__title-container {
      display: flex;
      align-items: center;

      &__label {
        margin-left: 8px;
      }
    }

    &__text {
      margin-top: 16px;
    }

    &__text-2 {
      margin-top: 32px;
    }

    &__title-2 {
      color: var(--color-main);
      margin-top: 32px;
    }

    &__list {
      margin-top: 16px;

      &__item {
        background-color: var(--color-gray-50);
        border-radius: 4px;
        padding: 16px;

        &__value {
          margin-top: 8px;

          &__mark {
            margin-left: 8px;
          }
        }
      }
    }

    &__button-group {
      display: flex;
      justify-content: flex-end;
      margin-top: 32px;

      > *:not(:nth-last-child(1)) {
        margin-right: 8px;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
    }

    &--boosted-example,
    &--boosted-active {
      border-color: var(--color-main);

      .boost-tile__title-container__title {
        color: var(--color-main);
      }

      .boost-tile__list__item__value__mark {
        color: var(--color-main);
      }
    }
  }

  .explain-list {
    display: flex;
    width: calc(100% + 8px + 8px);
    margin-top: 32px;
    position: relative;
    left: -8px;

    &__title {
      margin-bottom: 8px;
    }

    &__property {
      margin-top: 4px;
    }

    &__value {
      color: var(--color-main);
    }

    &__item {
      width: calc(33.33% - 8px - 8px);
      margin-right: 8px;
      margin-left: 8px;
      position: relative;

      &__tile-list {
        display: flex;
        width: 100%;
        column-gap: 8px;

        &__item {
          flex: 1 0 auto;
          background-color: var(--color-gray-50);
          border-radius: 4px;
          border: 1px solid var(--color-gray-300);
          padding: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .boost {
    .boost-tile {
      &__footer {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .boost {
    .boost-tile {
      &__button-group {
        flex-wrap: wrap;
        justify-content: initial;

        > *:not(:nth-last-child(1)) {
          margin-right: initial;
          margin-bottom: 8px;
        }

        > * {
          width: 100%;
        }
      }

      &__footer {
        align-items: initial;
        justify-content: initial;

        & > * {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}
