.title-with-element {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--mar-top-small {
    margin-top: 16px;
  }

  &--mar-top-medium {
    margin-top: 24px;
  }

  &--mar-top-large {
    margin-top: 32px;
  }

  &--mar-top-large-2 {
    margin-top: 64px;
  }

  &--mar-bot-small {
    margin-bottom: 16px;
  }

  &--mar-bot-medium {
    margin-bottom: 24px;
  }

  &--mar-bot-large {
    margin-bottom: 32px;
  }

  &--mar-bot-large-2 {
    margin-bottom: 64px;
  }

  &__text {
    margin-right: 24px;
  }
}