.template {
  .header {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-300);
    position: sticky;
    top: 0px;
    z-index: 55;

    :global(.wrapper) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__part-1 {
      display: flex;
      justify-content: space-between;
    }

    &__part-2 {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 24px;
    }
  }

  .section {
    :global(.wrapper) {
      padding-top: initial;
      padding-bottom: initial;
    }

    .content {
      display: flex;
    }

    .sidebar {
      border-right: 1px solid var(--color-gray-300);
      width: 304px;
      padding: 32px 32px 32px 0px;
      position: relative;

      &:before {
        content: '';
        background-color: var(--color-gray-50);
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0px;
        left: calc(-50vw + 304px);
        z-index: -1;
      }

      &__list {
        position: sticky;
        top: calc(161px + 32px);

        &__item {
          color: var(--color-gray-800);
          border-left: 1px solid var(--color-gray-300);
          padding: 8px 8px 8px 12px;
          position: relative;
          cursor: pointer;

          &:before {
            content: '';
            background-color: var(--color-main);
            opacity: 0;
            width: 2px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: -1px;
            transition: var(--transition-fast);
          }

          &--active {
            color: var(--color-main);

            &:before {
              opacity: 1;
            }
          }
        }
      }
    }

    .main {
      width: calc(100% - 304px);
      padding: 32px 0px 32px 32px;
      padding-bottom: 80vh;
    }
  }
}

@media screen and (max-width: 1279px) {
  .template {
    .header {
      .title {
        width: 65%;
      }
    }

    .section {
      .sidebar {
        width: 270px;
        padding: 16px 16px 16px 0px;

        &:before {
          width: 50vw;
          left: calc(-50vw + 270px);
        }

        &__list {
          top: calc(201px + 16px);
        }
      }

      .main {
        width: calc(100% - 270px);
        padding: 16px 0px 16px 16px;
        padding-bottom: 60vh;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .template {
    .header {
      background-color: var(--color-gray-50);
      position: relative;

      :global(.wrapper) {
        padding-top: 48px;
        padding-bottom: 48px;
      }

      &__part-1 {
        display: flex;
        justify-content: space-between;

        .logo {
          order: 1;
        }

        .back-button {
          order: 2;
        }
      }

      &__part-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: initial;
        align-items: initial;

        .title {
          width: 100%;
        }

        .last-modified-label {
          margin-top: 24px;
        }
      }
    }

    .section {
      .content {
        flex-wrap: wrap;
      }

      .sidebar {
        border: initial;
        width: 100%;
        padding: 32px 0px 0px 0px;

        &:before {
          display: none;
        }

        &__list {
          position: relative;
          top: initial;
        }
      }

      .main {
        width: 100%;
        padding: initial;
        margin-top: 32px;
        padding-bottom: 50vh;
      }
    }
  }
}
