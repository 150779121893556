.nav-link {
  line-height: 100%;
  display: inline-flex;
  align-items: center;
  color: var(--color-main);
  position: relative;
  cursor: pointer;

  &--smaller {
    font-size: 12px;
    font-weight: 500;
  }

  &--small {
    font-size: 16px;
    font-weight: 700;
  }

  &--small-2 {
    font-size: 16px;
    font-weight: 300;
  }

  &--small-3 {
    font-size: 16px;
    font-weight: 400;
  }

  &--large {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;

    svg {
      transition: var(--transition-fast);
    }
  }

  &__text {
    position: relative;
    transition: var(--transition-fast);
  }

  &__text + &__icon {
    margin-left: 8px;
  }

  &__icon + &__text {
    margin-left: 8px;
  }

  &:hover {
    .nav-link__text {
      color: var(--color-dark-orange);
    }

    .nav-link__icon svg {
      fill: var(--color-dark-orange) !important;
    }
  }

  &--with-border {
    .nav-link__text {
      &:after {
        content: '';
        background-color: var(--color-main);
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        transition: var(--transition-fast);
      }
    }

    &:hover {
      .nav-link__text {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &--color-blue {
    .nav-link__text {
      color: var(--color-cyan);
    }

    .nav-link__icon svg {
      fill: var(--color-cyan) !important;
    }

    .nav-link__text {
      &:after {
        background-color: var(--color-cyan);
      }
    }
  }

  &--color-dark {
    .nav-link__text {
      color: var(--color-black);
    }

    .nav-link__icon svg {
      fill: var(--color-black) !important;
    }

    .nav-link__text {
      &:after {
        background-color: var(--color-black);
      }
    }
  }

  &--color-white {
    .nav-link__text {
      color: var(--color-white);
    }

    .nav-link__icon svg {
      fill: var(--color-white) !important;
    }

    .nav-link__text {
      &:after {
        background-color: var(--color-white);
      }
    }
  }

  &--disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }
}
