.header {
  width: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 99;
}

.header.header--fixed {
  position: fixed;
}

.header .background {
  background-color: var(--color-space-blue-1);
}

.header .wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.header .el-logo {
  display: block;
  width: 154px;
  height: auto;
  margin-right: 32px;
}

.header .el-menu > li > a {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-white);
  position: relative;
  transition: var(--transition-fast);
}

.header .el-menu > li > a:hover {
  color: var(--color-main);
}

.header .el-menu > li > a.active {
  color: var(--color-main);
  /* pointer-events: none; */
}

.header .el-menu > li:not(:nth-last-child(1)) {
  margin-right: 32px;
}

.header .el-role-selector {
  margin-right: 32px;
  position: relative;
}

.header .el-button-group-item:not(:nth-last-child(1)) {
  margin-right: 8px;
}

.header .el-logged-in-button-group-list > *:not(:nth-last-child(1)) {
  margin-right: 24px;
}

.header .el-logged-in-button-group-avatar-container {
  display: flex;
  align-items: center;
}

.header .el-logged-in-button-group-avatar-container-image {
  border-radius: 12px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}

.header .el-logged-in-button-group-avatar-container-name {
  color: var(--color-white);
  transition: var(--transition-fast);
}

.header .el-logged-in-button-group-avatar-container:hover .el-logged-in-button-group-avatar-container-name {
  color: var(--color-main);
}

.header.header--color-white .background {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-300);
}

.header.header--color-white .el-logo svg path {
  fill: var(--color-black);
}

.header.header--color-white .el-menu > li > a {
  color: var(--color-black);
}

.header.header--color-white .el-menu > li > a:hover {
  color: var(--color-main);
}

.header.header--color-white .el-menu > li > a.active {
  color: var(--color-main);
}

.header.header--color-white .el-logged-in-button-group-avatar-container-name {
  color: var(--color-black);
}

@media screen and (max-width: 1279px) {
  header {
    overflow: hidden;
  }

  .header .block-item-1,
  .header .block-item-2 {
    height: 40px;
  }

  .header .el-burger {
    margin-right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .header .wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .header .el-logo {
    width: 113px;
  }

  .header .el-burger {
    margin-right: 16px;
  }
}
