.form {
  position: relative;

  .small-col {
    max-width: 120px;
  }

  .label {
    margin-bottom: 4px;
  }

  .name-and-credit-cards-row {
    align-items: flex-end;
  }

  .credit-card-company-col {
    max-width: 161px;
  }

  .credit-card-company-group {
    display: flex;
    align-items: center;
    height: 48px;

    &__item {
      &:not(:nth-last-child(1)) {
        margin-right: 16px;
      }
    }
  }

  .stripe-element-container {
    :global(.StripeElement) {
      border-radius: 4px;
      border: 1px solid var(--color-gray-300);
      background: var(--color-white);
      display: block;
      width: 100%;
      padding-top: 16px;
      padding-right: 16px;
      padding-bottom: 14px;
      padding-left: 16px;
      position: relative;
      cursor: text;

      &:after {
        content: '';
        border: 2px solid var(--color-main);
        border-radius: 4px;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        pointer-events: none;
        box-sizing: border-box;
        transition: var(--transition-fast);
      }

      &:before {
        content: '';
        border: 1px solid var(--color-gray-700);
        border-radius: 4px;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        pointer-events: none;
        box-sizing: border-box;
        transition: var(--transition-fast);
      }
    }

    :global(.StripeElement:hover) {
      &:before {
        opacity: 1;
      }
    }

    :global(.StripeElement--focus) {
      &:after {
        opacity: 1;
      }
    }

    :global(.StripeElement--invalid) {
      &:before {
        opacity: 1;
        border-color: var(--color-red-400);
      }
    }
  }
}