@import '../shared/inputShared.module.scss';

.input {
  &__container {
    &__icon-right {
      opacity: 0.33;
      cursor: pointer;
      transition: var(--transition-fast);
    }
  }

  &.input--color-gray {
    input {
      &__icon-left,
      &__icon-right {
        svg {
          fill: var(--color-gray-700);
        }
      }
    }
  }

  &.input--value-hidden {
    .input__container {
      &__icon-right {
        opacity: 1;

        svg {
          fill: var(--color-black);
        }
      }
    }
  }
}
