.element {
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-gray-100);
  border-radius: var(--border-radius-1);
  width: 100%;
  min-height: 20px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 55;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    // outline: 1px solid red;
    display: block;
    width: 20px;
    min-height: 200%;
    height: 20px;
    margin: auto 0px;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: 0px;
    transform: skew(-33deg);
    animation-name: glareAnim1;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &:before {
    background-color: var(--color-white-50);
    animation-name: glareAnim1;
  }

  &:after {
    background-color: var(--color-white-25);
    animation-name: glareAnim2;
  }

  &--type-white {
    // border: 1px solid var(--color-gray-300);
    // background-color: #fff;

    &:before {
      background-color: var(--color-white-75);
    }

    &:after {
      background-color: var(--color-white-50);
    }
  }
}

@keyframes glareAnim1 {
  0% {
    left: -40px;
  }

  99% {
    left: calc(100% + 40px);
  }

  100% {
    left: -40px;
  }
}

@keyframes glareAnim2 {
  0% {
    left: -80px;
  }

  99% {
    left: calc(100% + 80px);
  }

  100% {
    left: -80px;
  }
}
