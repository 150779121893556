.container {
  border-top: 1px solid var(--color-white-25);
  width: 100%;
  height: calc(100lvh - 88px + 1px);
  position: fixed;
  top: calc(88px - 1px);
  left: 0px;
  z-index: 99;

  .background {
    background-color: var(--color-space-blue-1);
    background-image: url('../../../../../../asset/images/mobile_menu_bg.png');
    background-size: cover;
    background-position: right center;
  }

  .scroll-wrapper {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  :global(.wrapper) {
    height: 100%;
    padding-top: initial;
    padding-bottom: initial;
    position: relative;
  }

  .vertical-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .header {
    border-bottom: 1px solid var(--color-white-25);
    flex: 0 0 auto;
    padding-top: 40px;
    padding-bottom: 48px;

    &__block-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--logged {
        flex-wrap: wrap;

        .header__block-item {
          &:nth-child(1) {
            width: 50%;
          }

          &:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            width: 50%;
          }

          &:nth-child(3) {
            width: 50%;
            margin-top: 24px;
          }

          &:nth-child(4) {
            display: flex;
            justify-content: flex-end;
            width: 50%;
            margin-top: 24px;
          }
        }
      }
    }

    &__text {
      color: var(--color-white);
    }

    &__user {
      display: flex;
      align-items: center;

      &__avatar {
        border-radius: 19px;
        width: 64px;
        height: 64px;
        margin-right: 16px;
        overflow: hidden;
      }

      &__content {
        &__name {
          color: var(--color-white);
        }
      }
    }
  }

  .main {
    flex: 1 0 auto;
    padding-top: 48px;
    padding-bottom: 48px;

    .menu-nav {
      &__list {
        li {
          &:not(:nth-child(1)) {
            margin-top: 48px;
          }

          a {
            font-size: 20px;
            font-weight: 800;
            line-height: 150%;
            letter-spacing: -0.2px;
            color: var(--color-white);

            &:global(.active) {
              color: var(--color-main);
            }
          }
        }
      }
    }
  }

  .footer {
    border-top: 1px solid var(--color-white-25);
    flex: 0 0 auto;
    padding-top: 48px;
    padding-bottom: 40px;

    .socials-group {
      display: flex;
      align-items: center;
      margin-top: 24px;

      &__item {
        svg {
          transition: var(--transition-fast);
        }

        &:hover {
          svg {
            fill: var(--color-white);
            fill-opacity: 1;
          }
        }
      }

      &__item:not(:nth-last-child(1)) {
        margin-right: 20px;
      }
    }

    &__content {
      color: var(--color-white);
      margin-top: 32px;

      &__text {
        margin-top: 16px;
      }
    }
  }

  .bottom {
    background-color: var(--color-space-blue-1);
    padding-top: 16px;
    padding-bottom: 16px;
    position: sticky;
    bottom: 0px;
    left: 0px;

    &:before {
      content: '';
      background-color: var(--color-white-25);
      width: calc(100% + 16px + 16px);
      height: 1px;
      position: absolute;
      top: 0px;
      left: -16px;
    }

    &__button-group {
      display: flex;
      justify-content: space-between;

      &__item {
        width: calc(50% - 4px);

        &--is-single-true {
          width: 100%;
        }

        > * {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: calc(100lvh - 72px + 1px);
    top: calc(72px - 1px);

    .header {
      padding-top: 32px;
      padding-bottom: 32px;

      &__block-list {
        flex-wrap: wrap;

        &--logged {
          .header__block-item {
            &:nth-child(1) {
              display: block;
              width: 100%;
            }

            &:nth-child(2) {
              display: block;
              width: 100%;
              margin-top: 16px;
            }

            &:nth-child(3) {
              display: block;
              width: 100%;
              margin-top: 24px;
            }

            &:nth-child(4) {
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
        }
      }

      &__block-item {
        width: 100%;

        &:nth-child(2) {
          margin-top: 16px;
        }
      }
    }

    .main {
      padding-top: 32px;
      padding-bottom: 32px;

      .menu-nav {
        &__list {
          li {
            &:not(:nth-child(1)) {
              margin-top: 32px;
            }
          }
        }
      }
    }

    .footer {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
