.message {
  margin-top: 4px;

  &--state-alert {
    color: var(--color-red-400);
  }

  &--state-success {
    color: var(--color-green-400);
  }
}