.container {
  width: 100%; /* Width of the outside container */
}

input[type='range'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  height: 8px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  border-radius: 4px;
}

/* Track: webkit browsers */
input[type='range']::-webkit-slider-runnable-track {
  height: 8px;
  // background: #dadbe2;
  border-radius: 2px;
}

/* Track: Mozilla Firefox */
input[type='range']::-moz-range-track {
  height: 8px;
  // background: #dadbe2;
  border-radius: 2px;
}

/* Thumb: webkit */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-image: url('../../../asset/images/range_slider_handle.svg');
  position: relative;
  top: -6px;
}

/* Thumb: Firefox */
input[type='range']::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background-image: url('../../../asset/images/range_slider_handle.svg');
  border: none;
  outline: none;
  position: relative;
  top: -6px;
}

.steps {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  &__item {
    background-color: var(--Neutral-400);
    border-radius: 4px;
    width: 2px;
    height: 6px;
  }
}
