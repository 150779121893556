.sidebar {
  .link {
    display: flex;
    padding: 16px;
    position: relative;
    user-select: none;

    &:before {
      content: '';
      background-color: var(--color-gray-300);
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: var(--transition-fast);
    }

    &:after {
      content: '';
      opacity: 0;
      background-color: var(--color-main);
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: var(--transition-fast);
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;

      svg path {
        fill: var(--color-gray-700);
        transition: var(--transition-fast);
      }
    }

    &__text {
      transition: var(--transition-fast);
    }

    &:global(.active) {
      pointer-events: none;

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }

      .link__icon {
        svg path {
          fill: var(--color-main);
        }
      }

      .link__text {
        color: var(--color-main);
      }
    }

    &:hover {
      &:before {
        background-color: var(--color-gray-800);
      }

      .link__icon {
        svg path {
          fill: var(--color-black);
        }
      }
  
      .link__text {
        color: var(--color-black);
      }
    }
  }
}