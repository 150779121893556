.section {
  :global(.wrapper) {
    padding-top: 24px;
  }

  .content {
    max-width: 416px;
    margin-right: auto;
    margin-left: auto;

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 142px;
    }

    .text {
      margin-top: 24px;
    }

    .button {
      width: 100%;
      margin-top: 40px;
    }
  }
}