.text-billet {
  position: relative;

  &__top-part {
    // background: var(--color-gray-200);
    // border: 1px solid var(--color-gray-300);
    // border-radius: var(--border-radius-2);
    display: flex;
    align-items: flex-end;
    // padding: 16px 40px;
  }

  &__bottom-part {
    text-align: center;
    margin-top: 16px;
  }
}
