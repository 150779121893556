.wrapper {
  max-width: calc(var(--wrap-max-width-1) + var(--wrap-pad-1) * 2);
  padding-top: var(--wrap-pad-2);
  padding-right: var(--wrap-pad-1);
  padding-bottom: var(--wrap-pad-2);
  padding-left: var(--wrap-pad-1);
  margin-right: auto;
  margin-left: auto;
}

.wrapper.type-1 {
  max-width: calc(var(--wrap-max-width-2) + var(--wrap-pad-1) * 2);
}

.background {
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;

  overflow: hidden;

  pointer-events: none;

  &--color-gray {
    background-color: var(--color-gray-50);
  }

  &--color-blue {
    background-color: var(--color-space-blue-1);
  }

  &--color-blue-dark {
    background-color: var(--color-space-dark);
  }

  &--color-blue-gradient {
    background: linear-gradient(90deg, #24296f 0.05%, #010534 99.68%);
  }

  &--color-neutral-primary {
    background: var(--Background-Neutral-Primary);
  }

  &--color-neutral-secondary {
    background: var(--Background-Neutral-Secondary);
  }

  &__inner {
    min-width: 1920px;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    position: absolute;
    right: -200%;
    left: -200%;
  }
}

.flex {
  display: flex;
}

.flex.middle {
  align-items: center;
}

.flex.center {
  justify-content: center;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.wrap {
  flex-wrap: wrap;
}

.section-divider + * .background,
.section-divider + * + * .background,
.section-divider + * + * + * .background {
  height: calc(100% + 5vw);
}

.tile {
  border-radius: 16px;
  border: 1px solid var(--Border-Neutral-Default);
  background: var(--Background-Neutral-Container);
  box-shadow: var(--shadow-md);
  padding: 24px;
  position: relative;

  &--color-main {
    color: var(--color-white);
    background-color: var(--color-main);
    border: initial;
    box-shadow: initial;
  }

  &--color-transparent {
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.04);
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: initial;
  }

  &--no-shadow {
    box-shadow: initial;
  }

  &--padding-32px {
    padding: 32px;
  }

  &--padding-0px {
    padding: 0px;
  }

  &--border-radius-8px {
    border-radius: 8px;
  }
}

.line-divider {
  background-color: var(--color-gray-300);
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.invisible-divider {
  height: 100px;
}

.invisible-divider {
  &--60px {
    height: 60px;
  }
}

@media screen and (max-width: 1279px) {
  .wrapper {
    padding-right: 16px;
    padding-left: 16px;
  }

  .tile {
    &--padding-32px {
      padding: 24px;
    }
  }

  .invisible-divider {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .section + .section-divider,
  .section + .section-divider + * + .section-divider,
  .section + .section-divider + * + .section-divider + * + .section-divider,
  section + .section-divider,
  section + .section-divider + * + .section-divider,
  section + .section-divider + * + .section-divider + * + .section-divider {
    margin-top: -6.65vw;
  }
}
