.container {
  background-color: var(--color-light-orange);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;

  &__number {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
    color: var(--color-white);
    background-color: var(--color-main);
    border: 2px solid var(--color-white);
    border-radius: 8px;
    padding: 4px 6px 3px 6px;
    position: absolute;
    top: -12px;
    right: -12px;
  }

  &--color-blank {
    background-color: var(--color-white-15);

    .container__icon {
      svg {
        fill: var(--color-white) !important;
      }
    }

    .container__number {
      border-color: var(--color-space-blue-1);
    }
  }

  &--link {
    cursor: pointer;
  }
}
